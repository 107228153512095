import React, { useState, useEffect, createRef /*, useRef*/ } from 'react';

import { MenuOutlined /*, CaretDownOutlined*/ } from '@ant-design/icons';
import {
  /*Row, Col, */ Menu,
  Dropdown,
  Tooltip,
  Layout /*, Button*/,
} from 'antd';
import { Play, X } from 'lucide-react';
// import { ChevronLeft, ChevronRight } from 'lucide-react';
import {
  RouteComponentProps,
  NavLink,
  Link,
  useHistory,
} from 'react-router-dom';

import { Footer } from '../../components';
import // icon1,
// icon2,
// icon3,
// icon4,
// icon6,
// play,
// videoPic,
'../../images';
import { green, isStudentUrl } from '../../utils';
import Navigation from './Navigation';
import Section from './Section';
import styles from './style2.module.scss';
import './app.css';

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
  formType: string;
  setFormType: Function;
  user: Object;
}

// const IconFont = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });
const { Header } = Layout;
// const images = [
//   '/path-to-image1.jpg',
//   '/path-to-image2.jpg',
//   '/path-to-image3.jpg',
// ];

const HomePage: React.FC<Props> = () => {
  const history = useHistory();
  const isStudent = isStudentUrl();

  // const vidRef = useRef();
  // const vid2Ref = useRef();
  const [activeSection, setActiveSection] = useState('homeVideo');
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  // const prevSlide = () => {
  //   setCurrentIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  // };
  //
  // const nextSlide = () => {
  //   setCurrentIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  // };
  const videoUrl =
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success.mp4';
  const [pageHeight, setPageHeight] = useState<number>();
  // const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
  // const [autoplay, setAutoPlay] = useState<boolean>(false);
  const [testimonialVideoPlaying, setTestimonialVideoPlaying] =
    useState<boolean>(false);
  // useEffect(() => {
  //   const interval = setInterval(nextSlide, 3000); // Auto-slide every 3 seconds
  //   return () => clearInterval(interval);
  // }, []);
  // const [testimonialVideoMuted, setTestimonialVideoMuted] = useState<boolean>(
  //   true,
  // );
  useEffect(() => {
    if (activeSection === 'bestreturn') {
      const interval = setInterval(() => {
        goToNext();
      }, 3000); // Auto-slide every 3 seconds
      return () => clearInterval(interval);
    }
  }, [currentIndex, activeSection]);

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1,
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1,
    );
  };
  const imageUrls = [
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/1.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/2.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/3.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/4.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/5.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/6.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/7.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/8.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/9.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/10.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/11.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/12.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/13.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/14.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/15.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/16.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/17.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/18.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/19.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/20.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/21.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/22.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/23.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/24.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/25.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/26.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/27.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/28.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/29.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/30.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/31.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/32.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/33.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/34.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/35.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/36.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/37.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/38.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/39.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/40.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/41.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/42.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/43.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/44.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/45.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/46.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/47.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/48.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/49.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/50.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/51.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/52.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/53.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/54.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/55.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/56.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/57.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/58.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/59.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/60.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/61.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/62.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/63.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/64.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/65.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/66.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/67.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/68.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/69.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/70.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/71.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/72.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/73.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/74.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/75.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/76.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/77.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/78.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/79.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/80.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/81.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/82.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/83.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/84.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/85.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/86.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/87.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/88.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/89.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/90.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/91.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/92.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/93.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/94.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/95.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/96.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/97.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/98.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/99.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/100.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/101.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/102.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/103.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/104.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/105.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/106.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/107.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/108.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/109.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/110.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/111.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/112.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/113.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/114.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/115.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/116.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/117.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/118.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/119.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/120.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/121.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/122.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/123.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/124.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/125.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/126.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/127.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/128.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/129.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/130.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/131.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/132.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/133.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/134.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/135.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/136.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/137.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/138.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/139.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/140.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/141.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/142.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/143.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/144.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/145.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/146.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/147.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/148.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/149.jpg',
  ];

  useEffect(() => {
    const code = window.location.href.includes('code');
    const error = window.location.href.includes('PostConfirmation');
    if (code && !error) {
      history.push('/dashboard');
    }
    setTimeout(() => {
      if (code) {
        window.location.reload();
      }
    }, 2000);
    console.log(testimonialVideoPlaying);
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', (e) => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });
  }, []);
  useEffect(() => {
    if (activeSection !== 'homeVideo') {
      // vidRef.current.pause();
      setTestimonialVideoPlaying(false);
    }
    // if (activeSection !== 'whyThinkSabio') {
    //   vid2Ref.current.pause();
    // }
  }, [activeSection]);

  // const playVideo = () => {
  //   setVideoPlaying(true);
  //   vid2Ref.current.play();
  // };
  //
  // const playTestimonialVideo = () => {
  //   setTestimonialVideoPlaying(true);
  //   vidRef.current.play();
  // };

  const refs = [
    'homeVideo',
    'homebanner',
    'whyThinkSabio',
    'aboutThinksabio',
    'bestreturn',
    'community',
    'authenticity',
    'captain',
    'value',
    'invest',
  ].reduce((refsObj, i) => {
    refsObj[i] = createRef();
    return refsObj;
  }, {});
  const handleCLick = (name) => {
    refs[name].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const menu = (
    <Menu className={styles.mobileMenuNavLink}>
      {/*<Menu.Item key="0">
        <NavLink to="/stories">Stories</NavLink>
      </Menu.Item>*/}
      <Menu.Item key="0">
        <Tooltip
          placement="bottom"
          title={
            <>
              <div
                // className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Overview',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Overview</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Our Features',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Our Features</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Success Stories',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Success Stories</span>
                  </div>
                </NavLink>
              </div>
            </>
          }
        >
          <span style={{ fontWeight: 'bold' }}>Who We Are</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to="/login">Login</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to="/sign-up">Sign Up</NavLink>
      </Menu.Item>
      {/*<Menu.Item key="3">*/}
      {/*  <NavLink to="/donate">Donate</NavLink>*/}
      {/*</Menu.Item>*/}
      <Menu.Item key="4">
        <Tooltip
          placement="bottom"
          title={
            <>
              <div
                className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink to="/contact-us">
                  <div style={{ padding: '10px' }}>
                    <span>Contact Us</span>
                  </div>
                </NavLink>
              </div>
              {/*<div*/}
              {/*  className={styles.navLinks}*/}
              {/*  style={{*/}
              {/*    padding: '0px 30px 0px 30px',*/}
              {/*    marginTop: '10px',*/}
              {/*    borderRadius: '5px',*/}
              {/*    backgroundColor: 'gainsboro',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <NavLink to="/privacy-policy">*/}
              {/*    <div style={{ padding: '10px' }}>*/}
              {/*      <span>Privacy Policy</span>*/}
              {/*    </div>*/}
              {/*  </NavLink>*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  className={styles.navLinks}*/}
              {/*  style={{*/}
              {/*    padding: '0px 30px 0px 30px',*/}
              {/*    marginTop: '10px',*/}
              {/*    borderRadius: '5px',*/}
              {/*    backgroundColor: 'gainsboro',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <NavLink to="/tnc">*/}
              {/*    <div style={{ padding: '10px' }}>*/}
              {/*      <span>Terms & Conditions</span>*/}
              {/*    </div>*/}
              {/*  </NavLink>*/}
              {/*</div>*/}
            </>
          }
        >
          <span style={{ fontWeight: 'bold' }}>Support</span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const S0 = () => (
    <div
      style={{
        backgroundColor: '#21272C',
        display: 'flex',
        flexDirection: 'column', // Stack items vertically
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '2rem',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1200px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column' : 'row',
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        {/* Left Section */}
        <div
          style={{
            color: 'white',
            maxWidth: isMobile ? '100%' : '50%',
            padding: '1rem',
          }}
        >
          {/* ✅ Fixed Font Size to Match Image */}
          <h1
            style={{
              fontSize: isMobile ? '22px' : '25px', // Adjusted to fit in one line
              marginBottom: '1.5rem',
              marginTop: isMobile ? '0.5rem' : '',
              // fontWeight: 'bold',
              color: 'white',
              whiteSpace: isMobile ? '' : 'nowrap',
            }}
          >
            Try a <b>7-Day</b> Free Trial &{' '}
            <span style={{ fontWeight: 'bold', fontSize: '28px' }}>
              {' '}
              Get Access to Data-Driven Analytical Reports!
            </span>
          </h1>
          <p
            style={{
              fontSize: isMobile ? '20px' : '24px',
              fontWeight: 'bold',
              marginBottom: '1.5rem',
            }}
          >
            ✅ No Credit Card Required. <br />✅ Just Your Email.
          </p>

          <NavLink to="/free-trial">
            <button
              style={{
                backgroundColor: '#1abd50',
                color: 'white',
                fontWeight: 'bold',
                padding: isMobile ? '1rem' : '0.75rem 1.5rem',
                width: isMobile ? '100%' : 'auto',
                border: 'none',
                borderRadius: '0.5rem',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
              onMouseOut={(e) => (e.target.style.backgroundColor = '#1abd50')}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = 'transparent')
              }
            >
              Get Started
            </button>
          </NavLink>
        </div>

        {/* Right Section */}
        <div style={{ maxWidth: isMobile ? '100%' : '50%', padding: '1rem' }}>
          <img
            src="https://thinksabio-asset.s3.us-east-1.amazonaws.com/stockMarketGraph.png"
            alt="Stock Market"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
              transform: isMobile ? 'scale(1)' : 'scale(0.8)',
            }}
          />
        </div>
      </div>

      {/* Bottom Text */}
      <p
        style={{
          color: 'white',
          fontSize: isMobile ? '16px' : '20px',
          fontWeight: 'bold',
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        — Gain the tools and knowledge to create the wealth you deserve.
      </p>
    </div>
  );
  // {
  //   /*const S1 = () => (
  //   <div className={styles.homeBanner}>
  //     <img alt="home_page_img" className={styles.bannerImage} src={banner} />

  //     <div className={styles.homeBannerText}>
  //       <h2>
  //         Seed is powerless until it's planted,
  //         <br />
  //         Money is worthless until it's invested.
  //       </h2>
  //     </div>
  //     {/* <img alt="home_page_img" className={styles.muteImage} src={mute} />
  //   </div>
  // );*/
  // }

  const S2 = () => (
    <div
      style={{
        backgroundColor: '#21272C',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1200px',
          marginTop: '100px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column-reverse' : 'row', // Stack on mobile
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        {/* Right Section (Image First on Mobile) */}
        <div
          style={{
            color: 'white',
            maxWidth: isMobile ? '100%' : '50%',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <h1
            style={{
              color: 'whitesmoke',
              fontSize: isMobile ? '1.5rem' : '3rem',
              fontWeight: 'bold',
              marginBottom: '1rem',
            }}
          >
            Our Exclusive Reports
          </h1>

          {/* Reports List */}
          {[
            {
              title: '1. Diamonds Report',
              description:
                'Identifies high-potential stocks based on institutional accumulation, price trends, and volume upswings.',
            },
            {
              title: '2. Ultimate Report',
              description:
                'Highlights stocks with consistently increasing institutional ownership.',
            },
            {
              title: '3. 52 Week Low Reversal',
              description:
                'Tracks stocks rebounding from their lowest price, suggesting a potential trend reversal.',
            },
            {
              title: '4. Mark Minervini Strategy',
              description:
                'Focuses on stocks with strong earnings growth, relative strength, and technical breakouts.',
            },
          ].map((report, index) => (
            <div key={index} style={{ marginBottom: '0.5rem' }}>
              <h2
                style={{
                  fontSize: isMobile ? '1rem' : '2rem',
                  fontWeight: '600',
                  color: '#1abd50',
                }}
              >
                {report.title}
              </h2>
              <p style={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                {report.description}
              </p>
            </div>
          ))}
        </div>
        {/* Left Section (Text) */}
        <div
          style={{
            maxWidth: isMobile ? '100%' : '50%',
            padding: isMobile ? '1rem 0' : '0',
          }}
        >
          <img
            src="https://thinksabio-asset.s3.us-east-1.amazonaws.com/LandingPageReportSummary.png"
            alt="How it works"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </div>
      </div>
    </div>
  );
  const S1 = () => (
    <div
      style={{
        backgroundColor: '#21272C',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        padding: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1200px',
          marginTop: '100px',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: isMobile ? 'column-reverse' : 'row', // Stack on mobile
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        {/* Left Section (Image First on Mobile) */}
        <div
          style={{
            maxWidth: isMobile ? '100%' : '50%',
            padding: isMobile ? '1rem 0' : '0',
          }}
        >
          <img
            src="https://thinksabio-asset.s3.us-east-1.amazonaws.com/boardIconCharacter.png"
            alt="How it works"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '8px',
            }}
          />
        </div>

        {/* Right Section (Text) */}
        <div
          style={{
            color: 'white',
            maxWidth: isMobile ? '100%' : '50%',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <h1
            style={{
              color: 'whitesmoke',
              fontSize: isMobile ? '2rem' : '3rem',
              fontWeight: 'bold',
              marginBottom: '1rem',
            }}
          >
            How It Works
          </h1>

          {/* Steps List */}
          {[
            {
              title: '1. Sign up in less than a minute',
              description:
                'Sign up quickly and gain access to premium financial insights.',
            },
            {
              title: '2. Unlock exclusive institutional data',
              description:
                'Track real-time investments from top institutions and hedge funds.',
            },
            {
              title: '3. Stay Notified on Telegram',
              description:
                'Join our  Thinksabio Community Telegram groups to get real - time updates.',
            },
          ].map((step, index) => (
            <div key={index} style={{ marginBottom: '1.5rem' }}>
              <h2
                style={{
                  fontSize: isMobile ? '1.5rem' : '2rem',
                  fontWeight: '600',
                  color: '#1abd50',
                }}
              >
                {step.title}
              </h2>
              <p style={{ fontSize: isMobile ? '1rem' : '1.25rem' }}>
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  // const S3 = () => (
  //   <div className={styles.aboutThinksabio}>
  //     <h2>Inherent Features of ThinkSabio</h2>
  //     <p>
  //       <span>Join ThinkSabio </span>
  //       now for getting unlimited access 24/7/365 days to
  //     </p>
  //     <div className={styles.aboutThinksabioContent}>
  //       <Row>
  //         <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon1} />
  //             <p>7,100+</p>
  //           </div>
  //           <h6>Stock's prices and their trends</h6>
  //         </Col>
  //         <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon2} />
  //             <p>7,100+</p>
  //           </div>
  //           <h6>
  //             We provide individual statistical report for each of the stocks
  //             and forecast value for all the stocks
  //           </h6>
  //         </Col>
  //         <Col xs={24} sm={12} lg={6}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon3} />
  //             <p>40</p>
  //           </div>
  //           <h6>
  //             Technical analysis methods and strategies for picking best
  //             performing stocks
  //           </h6>
  //         </Col>
  //         <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon4} />
  //             <p>10,000+</p>
  //           </div>
  //           <h6>
  //             Institution's quarterly, daily stock holdings from Security
  //             Exchange Commission(SEC)
  //           </h6>
  //         </Col>
  //         {/*<Col xs={24} sm={12} lg={9}>
  //         {/* <Col xs={24} sm={12} lg={9}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon5} />
  //             <p>40+</p>
  //           </div>
  //           <h6>
  //             Technical analysis methods and strategies for picking best winning
  //             stocks
  //           </h6>
  //         </Col>
  //         </Col> */}
  //         <Col xs={24} sm={12} lg={6}>
  //           <div className={styles.aboutIconText}>
  //             <img alt="home_page_img" src={icon6} />
  //             <p>3,000+</p>
  //           </div>
  //           <h6>Insider's buying/selling information from SEC</h6>
  //         </Col>
  //       </Row>
  //     </div>
  //   </div>
  // );

  const S3 = () => (
    <div className={styles.bestReturn}>
      <div
        style={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row', // Stack on mobile
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh', // Reduced height since footer moved
          backgroundColor: '#21272C',
          color: 'white',
          padding: '2rem',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '1300px',
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            textAlign: isMobile ? 'center' : 'left',
            padding: '2rem',
          }}
        >
          {/* Left Side */}
          <div
            style={{
              width: isMobile ? '100%' : '50%',
              textAlign: 'left',
              borderRight: isMobile ? 'none' : '1px solid whitesmoke',
              paddingBottom: isMobile ? '1rem' : '0',
            }}
          >
            <div style={{ marginRight: isMobile ? '0' : '100px' }}>
              <h1
                style={{
                  fontSize: isMobile ? '2.5rem' : '3rem',
                  fontWeight: 'bold',
                  letterSpacing: '1px',
                  lineHeight: '1.2',
                  color: 'whitesmoke',
                  textAlign: isMobile ? 'center' : 'left',
                }}
              >
                Think
                <span style={{ color: 'whitesmoke' }}>Sabio</span> <br />
                <span
                  style={{
                    color: 'whitesmoke',
                    marginLeft: isMobile ? '' : '235px',
                  }}
                >
                  Testimonials
                </span>
              </h1>
              <p
                style={{
                  color: '#22c55e',
                  fontStyle: 'italic',
                  fontSize: '1.2rem',
                  marginTop: '0.5rem',
                  marginLeft: isMobile ? '' : '235px',
                  textAlign: isMobile ? 'center' : 'left',
                }}
              >
                "Voices of Success"
              </p>
            </div>
          </div>

          {/* Right Side - Video/Thumbnail */}
          <div
            style={{
              width: isMobile ? '100%' : '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
              position: 'relative',
            }}
          >
            {!isOpen ? (
              <div
                style={{
                  width: isMobile ? '90%' : '535px',
                  height: isMobile ? '250px' : '300px',
                  backgroundSize: 'contain',
                  backgroundImage:
                    'url("https://thinksabio-asset.s3.us-east-1.amazonaws.com/ProudThinkSabiens.jpg")',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <button
                  style={{
                    width: '4rem',
                    height: '4rem',
                    borderRadius: '50%',
                    backgroundColor: '#22c55e',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                    cursor: 'pointer',
                    opacity: 0.9,
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  <Play size={40} color="white" />
                </button>
              </div>
            ) : (
              <div
                style={{
                  width: '100%',
                  position: 'relative',
                  marginTop: '20px',
                }}
              >
                <video
                  controls
                  autoPlay
                  style={{ width: '100%', borderRadius: '8px' }}
                >
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <button
                  onClick={() => setIsOpen(false)}
                  style={{
                    position: 'absolute',
                    top: '-10px',
                    right: '-10px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    width: '2rem',
                    height: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <X size={16} />
                </button>
              </div>
            )}
            {!isOpen && (
              <p
                style={{
                  fontSize: '1.2rem',
                  marginTop: '1rem',
                  lineHeight: '1.5',
                  textAlign: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', color: '#22c55e' }}>
                  Real stories, Real{' '}
                </span>
                <span style={{ fontWeight: 'bold', color: '#22c55e' }}>
                  Results
                </span>
                <br />
                Hear from those who’ve experienced it firsthand.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const S4 = () => (
    <div
      style={{
        backgroundColor: '#21272C',
        display: 'flex',
        flexDirection: 'column', // Ensure content stacks properly
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Keep it full height
        padding: '2rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: isMobile ? 'center' : 'left',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: isMobile ? '100%' : '80%',
            paddingTop: '150px',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#21272C',
            height: '70vh',
            position: 'relative',
          }}
        >
          {/* Left Arrow */}
          <button
            onClick={goToPrev}
            style={{
              position: 'absolute',
              left: '10%',
              backgroundColor: '#1abd50',
              border: 'none',
              color: 'white',
              fontSize: '1.5rem',
              padding: '10px 10px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#1abd50')}
            onMouseOver={(e) =>
              (e.target.style.backgroundColor = 'transparent')
            }
          >
            &#8592;
          </button>

          {/* Image */}
          <img
            src={imageUrls[currentIndex]}
            alt="Smart Money"
            style={{
              width: isMobile ? '100%' : '100%',
              height: 'auto',
              transform: isMobile ? 'scale(0.5)' : 'scale(0.4)',
              borderRadius: '8px',
            }}
          />

          {/* Right Arrow */}
          <button
            onClick={goToNext}
            style={{
              position: 'absolute',
              right: '10%',
              backgroundColor: '#1abd50',
              border: 'none',
              color: 'white',
              fontSize: '1.5rem',
              padding: '10px 10px',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#1abd50')}
            onMouseOver={(e) =>
              (e.target.style.backgroundColor = 'transparent')
            }
          >
            &#8594;
          </button>
        </div>

        {/* Right Section - Text */}
        <h2
          style={{
            fontSize: isMobile ? '2.5rem' : '4rem',
            fontWeight: 'bold',
            transform: isMobile ? '' : 'scale(0.8)',
            marginTop: isMobile ? '1rem' : '',
            marginBottom: isMobile ? '1rem' : '20px',
            marginRight: isMobile ? '0' : '150px',
            marginLeft: isMobile ? '0' : '150px',
            color: 'white',
            lineHeight: '1',
            textAlign: isMobile ? 'center' : 'left',
          }}
        >
          <span style={{ color: '#22c55e' }}>&ldquo;ThinkSabio</span>
          <span
            style={{ fontSize: isMobile ? '1.2rem' : '1.5rem', color: 'white' }}
          >
            {' '}
            follows
          </span>
          <br />
          <span
            style={{ color: 'white', marginLeft: isMobile ? '0' : '300px' }}
          >
            Smart Money&rdquo;
          </span>
        </h2>
        <div style={{ width: '105%' }}>
          <Footer />
        </div>
      </div>
    </div>
  );

  // const S5 = () => (
  //   <div className={styles.community}>
  //     <div className={styles.communityContent}>
  //       <div className={styles.communityContentText}>
  //         <h2>Community Involvement</h2>
  //         <p>Tested- Proven-Successful with 10,000+ ThinkSabio community.</p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '50%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //       <div className={styles.communityContentImage}>
  //         <img
  //           alt="home_page_img"
  //           src="https://thinksabio-asset.s3.amazonaws.com/community.png"
  //         />
  //       </div>
  //     </div>
  //   </div>
  // );

  // const S6 = () => (
  //   <div className={styles.bestReturn}>
  //     <div className={styles.bestReturnContentText}>
  //       <div className={styles.returnTextimage}>
  //         <img
  //           alt="home_page_img"
  //           src="https://thinksabio-asset.s3.amazonaws.com/authentiicity.png"
  //         />
  //       </div>
  //       <div className={styles.bestReturnTextText}>
  //         <h2>We Are Focused</h2>
  //         <p>
  //           Profound data-mining techniques to bring stock's information quickly
  //           and intelligently to you.
  //         </p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '50%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //     </div>
  //   </div>
  // );

  // const S7 = () => (
  //   <div className={styles.captain}>
  //     <div className={styles.captainImage}>
  //       <img
  //         alt="home_page_img"
  //         src="https://thinksabio-asset.s3.amazonaws.com/captain.png"
  //       />
  //     </div>
  //     <div className={styles.captainContent}>
  //       <div className={styles.captainContentText}>
  //         <h2>Be a Captain</h2>
  //         <p>
  //           Our goal is helping our investor's accrue wealth by making them the
  //           captains of their own ship.
  //         </p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '60%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //       <div className={styles.captainRight}>
  //         <p>
  //           Our goal is helping our investor's accrue wealth by making them the
  //           captains of their own ship.
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );

  // const S8 = () => (
  //   <div className={styles.captain}>
  //     <div className={styles.valueContent}>
  //       <div className={styles.captainContentText}>
  //         <h2>Value</h2>
  //         <p>
  //           We provide mountain of confirmation, confidence, and courage to our
  //           investor's with our stock's data and research.
  //         </p>
  //         {isStudent ? (
  //           <Link to="/sign-up">
  //             <button style={{ color: green, width: '60%' }}>
  //               Join Us Now For Free
  //             </button>
  //           </Link>
  //         ) : (
  //           <Link to="/free-trial">
  //             <button>Try For Free</button>
  //           </Link>
  //         )}
  //       </div>
  //       <div className={styles.captainRight}>
  //         <p>
  //           We provide mountain of confirmation, confidence, and courage to our
  //           investor's with our stock's data and research.
  //         </p>
  //       </div>
  //     </div>
  //     <div className={styles.captainImage}>
  //       <img
  //         alt="home_page_img"
  //         src="https://thinksabio-asset.s3.amazonaws.com/value.jpg"
  //       />
  //     </div>
  //   </div>
  // );

  // const S9 = () => (
  //   <div className={styles.investBox}>
  //     <div className={styles.investBoxContent}>
  //       <div>
  //         <img
  //           src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
  //           alt="logo"
  //         />
  //       </div>
  //       {/*<h2>Stocks Simplified</h2>*/}
  //       {isStudent ? (
  //         <Link to="/sign-up">
  //           <button style={{ color: green, width: '60%' }}>
  //             Join Us Now For Free
  //           </button>
  //         </Link>
  //       ) : (
  //         <Link to="/free-trial">
  //           <button>Try For Free</button>
  //         </Link>
  //       )}
  //       <div className={styles.homeFooterBox}>
  //         <div className={styles.homeFooterContent}>
  //           <p>ThinkSabio © 2021</p>
  //           <ul>
  //             <li>
  //               <NavLink to="/tnc">Terms & Conditions</NavLink>
  //             </li>
  //             <li>
  //               <NavLink to="/privacy-policy">Privacy Policy</NavLink>
  //             </li>
  //             {/* <li>
  //             <NavLink to="">Privacy</NavLink>
  //           </li>
  //           <li>
  //             <NavLink to="">License</NavLink>
  //           </li>
  //           <li>
  //             <NavLink to="">Stories</NavLink>
  //           </li> */}
  //             <li>
  //               <NavLink to="/contact-us">Contact Us</NavLink>
  //             </li>
  //             {/* <li>
  //             <Link to="#">
  //               <TwitterOutlined />
  //             </Link>
  //             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  //             <Link to="#">
  //               <IconFont type="icon-facebook" />
  //             </Link>
  //           </li> */}
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className={styles.homePageContainer}>
      <div className={styles.headerSubHeader}>
        <Header
          className={styles.headerStaticMainBar}
          // style={{
          //   boxShadow:
          //     scrollY < 90
          //       ? '0px 0px 0px rgba(0, 0, 0, 0)'
          //       : '0px 4px 4px rgba(0, 0, 0, 0.05)',
          // }}
        >
          <div className={styles.logoHeader}>
            <div style={{ marginRight: 'calc(15vh - 8px)' }}>
              <img
                src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
                alt="logo"
              />
            </div>
            <div className={styles.homeHeader}>
              <div className={styles.headerContentBox}>
                <div className={styles.headerlink}>
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    className={styles.mobileMenuHome}
                    placement="bottomCenter"
                  >
                    <p
                      onClick={(e) => e.preventDefault()}
                      className="ant-dropdown-link"
                    >
                      <MenuOutlined />
                    </p>
                  </Dropdown>
                  <ul className={styles.menuLinkHome}>
                    <li>
                      <Tooltip
                        placement="bottom"
                        title={
                          <>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink
                                to={{
                                  pathname: '/WhoWeAre',
                                  state: {
                                    name: {
                                      properties: {
                                        name: 'Overview',
                                      },
                                    },
                                  },
                                }}
                              >
                                <div
                                  style={{ padding: '10px', width: '100px' }}
                                >
                                  <span>Overview</span>
                                </div>
                              </NavLink>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <div style={{ padding: '10px', width: '100px' }}>
                                <a
                                  href="https://blog.thinksabio.com/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Blog
                                </a>
                              </div>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink
                                to={{
                                  pathname: '/WhoWeAre',
                                  state: {
                                    name: {
                                      properties: {
                                        name: 'Our Features',
                                      },
                                    },
                                  },
                                }}
                              >
                                <div style={{ padding: '10px' }}>
                                  <span>Our Features</span>
                                </div>
                              </NavLink>
                            </div>
                            <div
                              className={styles.navLinks}
                              style={{
                                padding: '0px 30px 0px 30px',
                                marginTop: '10px',
                                borderRadius: '5px',
                                backgroundColor: 'gainsboro',
                              }}
                            >
                              <NavLink
                                to={{
                                  pathname: '/WhoWeAre',
                                  state: {
                                    name: {
                                      properties: {
                                        name: 'Success Stories',
                                      },
                                    },
                                  },
                                }}
                              >
                                <div style={{ padding: '10px' }}>
                                  <span>Success Stories</span>
                                </div>
                              </NavLink>
                            </div>
                          </>
                        }
                      >
                        <button
                          style={{
                            width: 150,
                            color: 'white !important',
                            backgroundColor: 'black !important',
                          }}
                        >
                          Who We Are
                        </button>
                      </Tooltip>
                    </li>
                    {isStudent ? (
                      ''
                    ) : (
                      <>
                        {/*<li>*/}
                        {/*  <NavLink*/}
                        {/*    to={{*/}
                        {/*      pathname: '/donate',*/}
                        {/*    }}*/}
                        {/*  >*/}
                        {/*    <button>Donate</button>*/}
                        {/*  </NavLink>*/}
                        {/*</li>*/}
                        <li>
                          <Link to="/free-trial">
                            <button style={{ color: green }}>Sign Up</button>
                          </Link>
                        </li>
                      </>
                    )}
                    {isStudent ? (
                      <li style={{ marginRight: '40px' }}>
                        <Link to="/sign-up">
                          <button
                            style={{
                              color: green,
                              width: '121%',
                              marginRight: '100px',
                              height: '50px',
                            }}
                          >
                            Join Us Now For Free
                          </button>
                        </Link>
                      </li>
                    ) : (
                      ''
                      // <li>
                      //   <Link to="/pricing">
                      //     <button>Pricing</button>
                      //   </Link>
                      // </li>
                    )}
                    <li>
                      <Link to="/login">
                        <button>Login</button>
                      </Link>
                    </li>
                    <li>
                      {/*<Tooltip*/}
                      {/*  placement="bottom"*/}
                      {/*  title={*/}
                      {/*    <>*/}
                      {/*<div*/}
                      {/*  className={styles.navLinks}*/}
                      {/*  style={{*/}
                      {/*    padding: '0px 30px 0px 30px',*/}
                      {/*    borderRadius: '5px',*/}
                      {/*    backgroundColor: 'gainsboro',*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <NavLink to="/contact-us">*/}
                      {/*    <div style={{ padding: '10px' }}>*/}
                      {/*      <span>Contact Us</span>*/}
                      {/*    </div>*/}
                      {/*  </NavLink>*/}
                      {/*</div>*/}
                      {/*<div*/}
                      {/*  className={styles.navLinks}*/}
                      {/*  style={{*/}
                      {/*    padding: '0px 30px 0px 30px',*/}
                      {/*    marginTop: '10px',*/}
                      {/*    borderRadius: '5px',*/}
                      {/*    backgroundColor: 'gainsboro',*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <NavLink to="/privacy-policy">*/}
                      {/*    <div style={{ padding: '10px' }}>*/}
                      {/*      <span>Privacy Policy</span>*/}
                      {/*    </div>*/}
                      {/*  </NavLink>*/}
                      {/*</div>*/}
                      {/*<div*/}
                      {/*  className={styles.navLinks}*/}
                      {/*  style={{*/}
                      {/*    padding: '0px 30px 0px 30px',*/}
                      {/*    marginTop: '10px',*/}
                      {/*    borderRadius: '5px',*/}
                      {/*    backgroundColor: 'gainsboro',*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  <NavLink to="/tnc">*/}
                      {/*    <div style={{ padding: '10px' }}>*/}
                      {/*      <span>Terms & Conditions</span>*/}
                      {/*    </div>*/}
                      {/*  </NavLink>*/}
                      {/*</div>*/}
                      {/*    </>*/}
                      {/*  }*/}
                      {/*>*/}
                      <button
                        style={{
                          width: 150,
                          color: 'white !important',
                          backgroundColor: 'black !important',
                        }}
                      >
                        <NavLink to="/contact-us">
                          <div style={{ padding: '10px' }}>
                            <span>Contact Us</span>
                          </div>
                        </NavLink>
                      </button>
                      {/*</Tooltip>*/}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*<Menu*/}
          {/*  theme="light"*/}
          {/*  mode="horizontal"*/}
          {/*  className={styles.headerMenuBar}*/}
          {/*  defaultSelectedKeys={['0']}*/}
          {/*></Menu>*/}
        </Header>
      </div>
      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="homeVideo"
        name="homeVideo"
      >
        <S0 />
      </Section>
      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="homebanner"
        name="homebanner"
      >
        <S1 />
      </Section>

      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="whyThinkSabio"
        name="whyThinkSabio"
      >
        <S2 />
      </Section>
      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="aboutThinksabio"
        name="aboutThinksabio"
      >
        <S3 />
      </Section>
      <Section
        activeCharacter={activeSection}
        setActiveCharacter={setActiveSection}
        pageHeight={pageHeight}
        refs={refs}
        id="bestreturn"
        name="bestreturn"
      >
        <S4 />
      </Section>
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="community"*/}
      {/*  name="community"*/}
      {/*>*/}
      {/*  <S5 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="authenticity"*/}
      {/*  name="authenticity"*/}
      {/*>*/}
      {/*  <S6 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="captain"*/}
      {/*  name="captain"*/}
      {/*>*/}
      {/*  <S7 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="value"*/}
      {/*  name="value"*/}
      {/*>*/}
      {/*  <S8 />*/}
      {/*</Section>*/}
      {/*<Section*/}
      {/*  activeCharacter={activeSection}*/}
      {/*  setActiveCharacter={setActiveSection}*/}
      {/*  pageHeight={pageHeight}*/}
      {/*  refs={refs}*/}
      {/*  id="invest"*/}
      {/*  name="invest"*/}
      {/*>*/}
      {/*  <S9 />*/}
      {/*</Section>*/}
      <Navigation
        items={[
          'homeVideo',
          'homebanner',
          'whyThinkSabio',
          'aboutThinksabio',
          'bestreturn',
          // 'community',
          // 'authenticity',
          // 'captain',
          // 'value',
          // 'invest',
        ]}
        activeCharacter={activeSection}
        handleCLick={handleCLick}
      />
    </div>
  );
};

export default HomePage;
