import React from 'react';

import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { currencyFormat } from '../../../utils';

const SECTOR_COLORS = {
  'Basic Materials': '#ffd8b1',
  'Communication Services': '#9A6324',
  'Consumer Cyclical': '#808000',
  // 'Consumer Cyclica': '#f032e6',
  'Consumer defensive': '#469990',
  Energy: '#fabed4',
  'Financial Services': '#dcbeff',
  Healthcare: '#f58231',
  Industrials: '#ffe119',
  'Real Estate': '#bfef45',
  Technology: '#3cb44b',
  Utilities: '#42d4f4',
  Others: '#4363d8',
  // Financial: '#911eb4',
  // 'Consumer defensive': '#f032e6',
  // 'Health Care': '',
  // Services: '#aaffc3',
  // 'Information Technology': '#e6194B',
  // "Services"
  // "N/A"
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (props) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    allocation,
    sector,
    fill,
    count,
    // allocationPercentage,
  } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? 'start' : 'end'}
      // textAnchor="end"
      dominantBaseline="central"
    >
      {/* {formatted_value} */}
      {`${sector} - ${(percent * 100).toFixed(2)}%
      ${
        allocation
          ? ` (${currencyFormat(
              allocation,
              false,
              false,
              2,
              false,
              true,
            )}, ${count} Symbols)`
          : ''
      } `}
    </text>
  );
};
const PieDiagram = ({ width, height, radius, data, dataKey }) => {
  return (
    <ResponsiveContainer width={width} height={height}>
      <PieChart
        width={width}
        height={height}
        // style={{
        //   display: 'inline',
        // }}
      >
        <Pie
          data={data}
          // cx={500}
          //cy={500}
          labelLine={true}
          label={(props) => renderCustomizedLabel(props)}
          outerRadius={radius}
          fill="#8884d8"
          dataKey={dataKey}
          // label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={SECTOR_COLORS[entry.sector]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieDiagram;
