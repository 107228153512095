import React from 'react';

import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './style.module.scss';

const StaticFooter: React.FC<Props> = () => {
  return (
    <div className={styles.footerMainBar}>
      {/* Top Section: Disclaimer */}
      <div className={styles.disclaimerSection}>
        <p className={styles.disclaimerText}>
          <b>*Disclaimer: </b> ThinkSabio is not a registered investment adviser
          or broker-dealer and does not provide individualized securities
          advice. Rather, it is a subscription service that provides information
          about securities derived from public sources, including information
          publicly available from the Securities and Exchange Commission.
          ThinkSabio does not guarantee the accuracy of the information derived
          from third party sources. ThinkSabio's recommendations are based on
          the information from such sources, and are not tailored to meet any
          individual investor's investment needs or objectives. Users of this
          site are responsible for making their own trading decisions.
        </p>
      </div>

      {/* Bottom Section: Follow Us, ThinkSabio, and Links */}
      <div className={styles.footerBottomSection}>
        {/* Left Section: Follow Us */}
        <div className={styles.followUsSection}>
          <p className={styles.followUsText}>Follow Us On</p>
          <div className={styles.socialIcons}>
            <a
              href="https://linkedin.com/company/thinksabio/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkedinOutlined className={styles.icon} />
            </a>
            <a
              href="https://x.com/thinksabio?t=xFIEfZRpGngvJFF_PawU2g&s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterOutlined className={styles.icon} />
            </a>
            <a
              href="https://youtube.com/@ThinkSabio"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeOutlined className={styles.icon} />
            </a>
            <a
              href="https://www.instagram.com/p/DFViUYyrj3/?
utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramOutlined className={styles.icon} />
            </a>
            <a
              href="https://facebook.com/profile.php?id=61554792440975"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookOutlined className={styles.icon} />
            </a>
          </div>
        </div>

        {/* Center Section: ThinkSabio © 2021 */}
        <div className={styles.centerSection}>
          <p className={styles.centerText}>ThinkSabio © 2021</p>
        </div>

        {/* Right Section: Navigation Links */}
        <div className={styles.navLinksSection}>
          <ul className={styles.navLinks}>
            <li>
              <Link to="/tnc" className={styles.navLink}>
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className={styles.navLink}>
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/contact-us" className={styles.navLink}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StaticFooter;
