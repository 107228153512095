import React from 'react';

import { get } from 'lodash';
import moment from 'moment-timezone';

import {
  getDailyReport,
  getRecentSECDailyReport,
  getRecentMutualFundsDailyReport,
  getRecentMutualFundsSymbolReport,
  getRecentSECSymbolReport,
  getRecentSECDetailReport,
  getMovingAvgReport,
  getUnderDollarReport,
  getOtherReport,
  getUuuReport,
  getNewlyBoughtReport,
  getIndividualReport,
  getDividendsReport,
  getSectorReport,
  getDecreasedDowTheoryReport,
  getIncreasedDowDailyTheoryReport,
  getDecreasedDowDailyTheoryReport,
  getIncreasedDowDailyPriceAndVolTheoryReport,
  getDecreasedDowDailyPriceAndVolTheoryReport,
  getIncreasedDowTheoryReport,
  getRecentSECYearlySymbolReport,
  getRecentSECYearlyDetailReport,
  getInsiderReport,
  getInsiderSymbolSummaryReport,
  getTrendingStockReport,
  getTrendingStocksActivesReport,
  getRecentByMutualFundReport,
  getRecentSECByTrxValReport,
  getTopTwentyFiveSymbolsData,
} from '../../graphql';
import { statusOptions, marketCapOptions, getLastQtrByIdx } from '../../utils';

// const statusOptions = [
//   { name: 'All', value: 'ALL' },
//   { name: 'Increased', value: 'INCREASED' },
//   { name: 'Decreased', value: 'DECREASED' },
//   { name: 'No Change', value: 'NO_CHANGE' },
//   { name: 'Newly Bought', value: 'NEWLY_BOUGHT' },
//   { name: 'Sold Off', value: 'SOLD_OFF' },
// ];
// const criterionOptions = ['COUNT', 'SHARES', 'BOTH'];

export const lastOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const specialOptions = [
  { name: 'Anson Funds Management LP', value: '1491072' },
  { name: 'ARMISTICE CAPITAL, LLC', value: '1601086' },
  { name: 'ARK Investment Management LLC', value: '0001697748' },
  { name: 'BAILLIE GIFFORD & CO', value: '1088875' },
  { name: 'BERKSHIRE HATHAWAY INC', value: '1067983' },
  { name: 'CHARLES SCHWAB INVESTMENT MANAGEMENT INC', value: '884546' },
  { name: 'CREATIVE PLANNING', value: '1540235' },
  { name: 'DIMENSIONAL FUND ADVISORS LP', value: '354204' },
  { name: 'FMR LLC', value: '315066' },
  { name: 'MORGAN STANLEY', value: '895421' },
  { name: 'Motley Fool Asset Management LLC', value: '1512814' },
  { name: 'Motley Fool Wealth Management, LLC', value: '1607978' },
  { name: 'Point72 Asset Management, L.P.', value: '0001603466' },
  { name: 'SABBY MANAGEMENT, LLC', value: '1535610' },
  { name: 'Valiant Capital Management, L.P', value: '1452689' },
];
// const typeOptions = [
//   { name: 'All', value: 'ALL' },
//   { name: 'Million Dollars', value: 'MILLION_DOLLARS' },
//   { name: 'Billion Dollars', value: 'BILLION_DOLLARS' },
//   { name: 'Only Top9', value: 'ONLY_TOP9' },
// ];

export const topOptions = [
  // { name: 'BAILLIE GIFFORD & CO', value: '1088875' },
  { name: 'BLUECREST CAPITAL MANAGEMENT LTD', value: '1610880' },
  { name: 'BRIDGEWATER ASSOCIATES, LP', value: '1350694' },
  { name: 'CITADEL ADVISORS LLC', value: '1423053' },
  { name: 'D. E. SHAW & CO., INC.', value: '1009207' },
  { name: 'ELEMENT CAPITAL MANAGEMENT LLC', value: '1535630' },
  { name: 'MILLENNIUM MANAGEMENT LLC', value: '1273087' },
  { name: 'RENAISSANCE TECHNOLOGIES LLC', value: '1037389' },
  { name: 'TIGER GLOBAL MANAGEMENT LLC', value: '1167483' },
  { name: 'TWO SIGMA INVESTMENTS, LP', value: '1179392' },
];
const codeArray = [
  'DRMF',
  'SSMF',
  'BFN',
  'SR',
  'AMFR',
  'student',
  'SAV',
  'QTBS',
  'DIL',
  'DIA',
  'earningsCalender',
];
// const sectorOptions = [
//   'Basic Industries',
//   'Capital Goods',
//   'Consumer Durables',
//   'Consumer Non-Durables',
//   'Consumer Services',
//   'Energy',
//   'Finance',
//   'Health Care',
//   'Miscellaneous',
//   'Public Utilities',
//   'Technology',
//   'Transportation',
//   'n/a',
// ];

export const cascaderValueByCode = (code: string) => {
  switch (code) {
    case 'AID':
      return ['Daily', 'All Institutions'];
    case 'SDY':
      return ['Daily', 'SC 13D/G', 'Latest Report'];
    case 'SSL':
      return ['Daily', 'SC 13D/G', 'By Symbol'];
    case 'SDL':
      return ['Daily', 'SC 13D/G', 'ALL'];
    case 'SAV':
      return ['Daily', 'SC 13D/G', 'Latest Top Buys and Sells'];
    case 'SSLC':
      return ['Daily', 'SC 13D/G', 'By Symbol (Cumulative)'];
    case 'SDLC':
      return ['Daily', 'SC 13D/G', 'Detail (Cumulative)'];
    case 'ADMA':
      return ['Daily', 'ADHOC', 'Moving AVG'];
    case 'ADUD':
      return ['Daily', 'ADHOC', 'Under a Dollar'];
    case 'DIL':
      return ['Daily', 'Insider', 'Latest'];
    case 'DISS':
      return ['Daily', 'Insider', 'By Symbol'];
    case 'DIA':
      return ['Daily', 'Insider', 'All'];
    case 'TS':
      return ['Daily', 'Trending Stocks'];
    case 'DTD':
      return ['Weekly', 'TS Weekly Downtrend'];
    case 'DTU':
      return ['Weekly', 'TS Weekly Uptrend'];
    case 'DDTD':
      return ['Daily', 'Downtrend', 'Price'];
    case 'DDTU':
      return ['Daily', 'Uptrend', 'Price'];
    case 'DDTDV':
      return ['Daily', 'Downtrend', 'Price & Volume'];
    case 'DDTUV':
      return ['Daily', 'Uptrend', 'Price & Volume'];
    case 'QTBS':
      return ['Quarterly', 'All Institutions', 'Top 25 Buys & Sells'];
    case 'AINB':
      return ['Quarterly', 'All Institutions', 'Newly Bought'];
    case 'AIU':
      return ['Quarterly', 'All Institutions', 'Ultimate Reports'];
    case 'AIO':
      return ['Quarterly', 'All Institutions', 'Other Reports'];
    case 'ITT':
      return ['Quarterly', 'Individual Reports', 'TOP-10 Hedge'];
    case 'IS':
      return ['Quarterly', 'Individual Reports', 'Special Reports'];
    case 'DR':
      return ['Quarterly', 'Dividends Summary'];
    case 'SR':
      return ['Quarterly', 'Sector wise allocation'];
    case 'TTNB':
      return ['Quarterly', 'TOP-10 Hedge Funds', 'Newly Bought'];
    case 'TTU':
      return ['Quarterly', 'TOP-10 Hedge Funds', 'Ultimate Reports'];
    case 'TTO':
      return ['Quarterly', 'TOP-10 Hedge Funds', 'Other Reports'];
    case 'DRMF':
      return ['Daily', 'Mutual Funds', 'Daily Report Mutual Funds'];
    case 'AMFR':
      return ['Daily', 'Mutual Funds', 'All Mutual Funds Report'];
    case 'BFN':
      return ['Daily', 'Mutual Funds', 'BY_FUND_NAME'];
    case 'SSMF':
      return ['Daily', 'Mutual Funds', 'BY_SYMBOL'];
    // case 'TTO':
    //   return ['Quarterly', 'TOP-10 Hedge Funds', 'Other Reports'];
    // case 'TTO':
    //   return ['Quarterly', 'TOP-10 Hedge Funds', 'Other Reports'];
    default:
      return ['Daily', 'All Institutions'];
  }
};

export const reportOptions = [
  {
    name: 'daily',
    label: 'Daily',
    value: 'daily',
    children: [
      {
        value: 'TRENDING_STOCK',
        label: 'Trending Stocks',
        // value: 'TS',
        children: [
          {
            name: 'TRENDING_STOCK',
            label: 'Trending Stocks',
            value: 'TS',
          },
        ],
      },
      {
        value: 'SEC_13D/G',
        label: 'SC 13D/G',
        children: [
          {
            name: 'SEC_TODAY_REPORT',
            label: 'Latest Report',
            value: 'SDY',
          },
          {
            name: 'SEC_BY_SYMBOL',
            label: 'By Symbol',
            value: 'SSL',
          },
          {
            name: 'SEC_FROM_JAN_2020',
            label: 'All',
            value: 'SDL',
          },
          {
            name: 'BY_APPROX_TXN_VAL',
            label: 'Latest Top Buys and Sells',
            value: 'SAV',
          },
          // {
          //   name: 'SEC_BY_SYMBOL_CUMULATIVE',
          //   label: 'By Symbol (Cumulative)',
          //   value: 'SSLC',
          // },
          // {
          //   name: 'SEC_FROM_JAN_2020_CUMULATIVE',
          //   label: 'Detail (Cumulative)',
          //   value: 'SDLC',
          // },
        ],
      },
      {
        value: 'DOW_DAILY_PRICE_UPTREND',
        label: 'Uptrend',
        //value: 'DTU',
        children: [
          {
            name: 'DOW_DAILY_PRICE_UPTREND',
            label: 'Price',
            value: 'DDTU',
          },
          {
            name: 'DOW_DAILY_THEORY_UPTREND_VOLUME',
            label: 'Price & Volume',
            value: 'DDTUV',
          },
        ],
      },
      {
        name: 'DOW_DAILY_PRICE_DOWNTREND',
        label: 'Downtrend',
        //value: 'DTD',
        children: [
          {
            name: 'DOW_DAILY_PRICE_DOWNTREND',
            label: 'Price',
            value: 'DDTD',
          },
          {
            name: 'DOW_DAILY_THEORY_DOWNTREND_VOLUME',
            label: 'Price & Volume',
            value: 'DDTDV',
          },
        ],
      },
      {
        label: 'Mutual Funds',
        value: 'MUTUAL_FUNDS',
        children: [
          {
            label: 'Latest Report',
            value: 'DRMF',
            hidden: false,
          },
          {
            label: 'All Mutual Funds Report',
            value: 'AMFR',
            hidden: false,
          },
        ],
      },

      {
        value: 'ALL_INSTITUTION',
        label: 'All Institutions',
        // value: 'AID',
        children: [
          {
            name: 'ALL_INSTITUTION',
            label: 'All Institutions',
            value: 'AID',
          },
        ],
      },
      {
        value: 'ADHOC',
        label: 'ADHOC',
        children: [
          {
            name: 'ADHOC_MOVING_AVG',
            label: 'Moving AVG',
            value: 'ADMA',
          },
          {
            name: 'ADHOC_UNDER_A_DOLLAR',
            label: 'Under a Dollar',
            value: 'ADUD',
          },
        ],
      },
      {
        value: 'INSIDER',
        label: 'Insider',
        // value: 'DI',
        children: [
          {
            name: 'INSIDER_LATEST',
            label: 'Latest',
            value: 'DIL',
          },
          {
            name: 'INSIDER_BY_SYMBOL',
            label: 'By Symbol',
            value: 'DISS',
          },
          {
            name: 'INSIDER_ALL',
            label: 'All',
            value: 'DIA',
          },
        ],
      },
    ],
  },
  {
    name: 'weekly',
    label: 'Weekly',
    value: 'weekly',
    children: [
      {
        name: 'DOW_THEORY_UPTREND',
        label: 'TS Weekly Uptrend',
        value: 'DTU',
      },
      {
        name: 'DOW_THEORY_DOWNTREND',
        label: 'TS Weekly DownTrend',
        value: 'DTD',
      },
    ],
  },
  {
    name: 'quarterly',
    label: 'Quarterly',
    value: 'quarterly',
    children: [
      {
        value: 'ALL_INSTITUTION',
        label: 'All Institutions',
        children: [
          {
            name: 'TOP_TWENTY_FIVE_SYMBOLS',
            label: 'Top 25 Buys & Sells',
            value: 'QTBS',
          },
          {
            name: 'ALL_INSTITUTION_NEWLY_BOUGHT',
            label: 'Newly Bought',
            value: 'AINB',
          },
          {
            name: 'ALL_INSTITUTION_ULTIMATE_REPORTS',
            label: 'Ultimate Reports',
            value: 'AIU',
          },
          {
            name: 'ALL_INSTITUTION_OTHER_REPORTS',
            label: 'Other Reports',
            value: 'AIO',
          },
        ],
      },
      {
        value: 'TOP-10_HEDGE_FUNDS',
        label: 'TOP-10 Hedge Funds',
        children: [
          {
            name: 'TOP_10_HEDGE_FUNDS_NEWLY_BOUGHT',
            label: 'Newly Bought',
            value: 'TTNB',
          },
          {
            name: 'TOP_10_HEDGE_FUNDS_ULTIMATE_REPORTS',
            label: 'Ultimate Reports',
            value: 'TTU',
          },
          {
            name: 'TOP_10_HEDGE_FUNDS_OTHER_REPORTS',
            label: 'Other Reports',
            value: 'TTO',
          },
        ],
      },
      {
        value: 'INDIVIDUAL_REPORTS',
        label: 'Individual Reports',
        children: [
          {
            name: 'INDIVIDUAL_TOP_10_HEDGE',
            label: 'TOP-10 Hedge',
            value: 'ITT',
          },
          {
            name: 'INDIVIDUAL_SPECIAL_REPORTS',
            label: 'Special Reports',
            value: 'IS',
          },
        ],
      },
      {
        value: 'DIVIDENDS_SUMMARY',
        label: 'Dividends Summary',
        // value: 'DR',
        children: [
          {
            name: 'DIVIDENDS_SUMMARY',
            label: 'Dividends Summary',
            value: 'DR',
          },
        ],
      },
      {
        value: 'SECTOR_WISE_ALLOCATION',
        label: 'Sector wise allocation',
        // value: 'SR',
        children: [
          {
            name: 'SECTOR_WISE_ALLOCATION',
            label: 'Sector wise allocation',
            value: 'SR',
          },
        ],
      },
    ],
  },
];

export const getAPIBasedOnType = (
  queryData: Object,
  type: string,
  selectedDateIndex: number,
  trendingStockType: string,
) => {
  const institutions = get(queryData, 'filters.institutions', []);
  const ciks = [];
  if (institutions && institutions.length > 0 && Array.isArray(institutions)) {
    // eslint-disable-next-line array-callback-return
    (institutions || []).map((inst) => {
      const value = inst.split('-#-');
      ciks.push(value[0]);
    });
  }
  delete queryData.institutions;
  switch (type) {
    case 'AID':
      // queryData.filters.date = moment()
      //   .tz('America/New_York')
      //   .add(selectedDateIndex, 'days')
      //   .format('YYYY-MM-DD');
      return getDailyReport(queryData);
    case 'DIL':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      queryData.filters.type = get(queryData, 'filters.type', '');
      return getInsiderReport(queryData);
    case 'DIA':
      queryData.filters.endDate = get(
        queryData,
        'filters.endDate',
        moment().tz('America/New_York').format('YYYY-MM-DD'),
      );
      queryData.filters.startDate = get(
        queryData,
        'filters.startDate',
        moment()
          .tz('America/New_York')
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      );
      queryData.filters.type = get(queryData, 'filters.type', '');
      return getInsiderReport(queryData);
    case 'DISS':
      queryData.filters.endDate = get(
        queryData,
        'filters.endDate',
        moment().tz('America/New_York').format('YYYY-MM-DD'),
      );
      queryData.filters.startDate = get(
        queryData,
        'filters.startDate',
        moment()
          .tz('America/New_York')
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      );
      queryData.filters.endDate = get(
        queryData,
        'filters.endDate',
        moment().tz('America/New_York').format('YYYY-MM-DD'),
      );
      queryData.filters.startDate = get(
        queryData,
        'filters.startDate',
        moment()
          .tz('America/New_York')
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      );

      return getInsiderSymbolSummaryReport(queryData);
    case 'SDY':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      // queryData.filters.institutions = ciks.length ? ciks : [];
      return getRecentSECDailyReport(queryData);
    case 'DRMF':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      // queryData.filters.institutions = ciks.length ? ciks : [];
      return getRecentMutualFundsDailyReport(queryData);
    case 'AMFR':
      return getRecentMutualFundsDailyReport(queryData);
    case 'SSMF':
      queryData.filters.endDate = get(
        queryData,
        'filters.endDate',
        moment().tz('America/New_York').format('YYYY-MM-DD'),
      );
      queryData.filters.startDate = get(
        queryData,
        'filters.startDate',
        moment()
          .tz('America/New_York')
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      );
      return getRecentMutualFundsSymbolReport(queryData);
    case 'BFN':
      return getRecentByMutualFundReport(queryData);
    case 'SSL':
      // queryData.filters.date = moment()
      //   .tz('America/New_York')
      //   .add(selectedDateIndex, 'days')
      //   .format('YYYY-MM-DD');
      // const sd = get(
      //   queryData,
      //   'filters.endDate ',
      //   moment().tz('America/New_York').format('YYYY-MM-DD'),
      // );
      queryData.filters.endDate = get(
        queryData,
        'filters.endDate',
        moment().tz('America/New_York').format('YYYY-MM-DD'),
      );
      queryData.filters.startDate = get(
        queryData,
        'filters.startDate',
        moment()
          .tz('America/New_York')
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      );
      return getRecentSECSymbolReport(queryData);
    case 'SSLC':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      return getRecentSECYearlySymbolReport(queryData);
    case 'SDL':
      // queryData.filters.date = moment()
      //   .tz('America/New_York')
      //   .add(selectedDateIndex, 'days')
      //   .format('YYYY-MM-DD');
      queryData.filters.endDate = get(
        queryData,
        'filters.endDate',
        moment().tz('America/New_York').format('YYYY-MM-DD'),
      );
      queryData.filters.startDate = get(
        queryData,
        'filters.startDate',
        moment()
          .tz('America/New_York')
          .subtract(1, 'years')
          .format('YYYY-MM-DD'),
      );

      return getRecentSECDetailReport(queryData);
    case 'SAV':
      queryData.filters.status = queryData.filters.status || 'INCREASED';
      return getRecentSECByTrxValReport(queryData);
    case 'QTBS':
      return getTopTwentyFiveSymbolsData(queryData);
    case 'SDLC':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      return getRecentSECYearlyDetailReport(queryData);
    case 'ADMA':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      return getMovingAvgReport(queryData);
    case 'ADUD':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      return getUnderDollarReport(queryData);
    case 'AINB':
      // queryData.filters.noOfQuarters = '2';
      queryData.filters.criterion = 'SHARES';
      queryData.filters.status = 'NEWLY_BOUGHT';
      queryData.filters.date = null;
      return getNewlyBoughtReport(queryData);
    case 'AIU':
      // queryData.filters = {};
      queryData.filters.noOfQuarters = queryData.filters.noOfQuarters || 2;
      // queryData.filters.multiStatus = ['INCREASED'];
      queryData.filters.criterion = queryData.filters.criterion || 'COUNT';
      return getUuuReport(queryData);
    case 'AIO':
      // queryData.filters.multiStatus = ['DECREASED'];
      return getOtherReport(queryData);
    case 'ITT':
      // queryData.filters = {};
      // queryData.cik = '1037389';
      return getIndividualReport(queryData);
    case 'IS':
      // queryData.filters = {};
      // queryData.cik = queryData.cik ? queryData.cik : '1601086';
      return getIndividualReport(queryData);
    case 'DR':
      // queryData.filters = {};
      delete queryData.isTop10;
      delete queryData.filters.isDow;
      delete queryData.filters.isNasdaq;
      delete queryData.filters.isSP;
      delete queryData.filters.sortByVol;
      delete queryData.cik;
      queryData.filters.category = queryData.filters.category || 'Quarterly';
      return getDividendsReport(queryData);
    case 'SR':
      // queryData.filters = {};
      queryData.filters.category =
        queryData.filters.category || 'All Institutions';
      delete queryData.isTop10;
      delete queryData.cik;
      return getSectorReport(queryData);
    case 'TTNB':
      queryData.isTop10 = true;
      queryData.filters.noOfQuarters = '2';
      queryData.filters.criterion = 'SHARES';
      queryData.filters.status = 'NEWLY_BOUGHT';
      return getNewlyBoughtReport(queryData);
    case 'TTU':
      queryData.isTop10 = true;
      queryData.filters.noOfQuarters = get(
        queryData,
        'filters.noOfQuarters',
        2,
      );
      queryData.filters.criterion = get(
        queryData,
        'filters.criterion',
        'COUNT',
      );
      return getUuuReport(queryData);
    case 'TTO':
      queryData.isTop10 = true;
      //  queryData.filters.multiStatus = ['INCREASED'];
      // queryData.filters = {};
      return getUuuReport(queryData);
    case 'DTU':
      queryData.isTop10 = true;
      queryData.filters.noOfWeeks = get(queryData, 'filters.noOfWeeks', 4);
      // queryData.filters = {};
      if (get(queryData, 'filters.symbols', []).length > 0) {
        delete queryData.filters.noOfWeeks;
      }
      return getIncreasedDowTheoryReport(queryData);
    case 'DTD':
      queryData.isTop10 = true;
      queryData.filters.noOfWeeks = get(queryData, 'filters.noOfWeeks', 4);
      // queryData.filters = {};
      if (get(queryData, 'filters.symbols', []).length > 0) {
        delete queryData.filters.noOfWeeks;
      }
      return getDecreasedDowTheoryReport(queryData);

    case 'DDTU':
      queryData.isTop10 = true;
      queryData.filters.noOfDays = get(queryData, 'filters.noOfDays', 2);
      // queryData.filters = {};
      if (get(queryData, 'filters.symbols', []).length > 0) {
        delete queryData.filters.noOfDays;
      }
      return getIncreasedDowDailyTheoryReport(queryData);

    case 'DDTD':
      queryData.isTop10 = true;
      queryData.filters.noOfDays = get(queryData, 'filters.noOfDays', 2);
      // queryData.filters = {};
      if (get(queryData, 'filters.symbols', []).length > 0) {
        delete queryData.filters.noOfDays;
      }
      return getDecreasedDowDailyTheoryReport(queryData);

    case 'DDTUV':
      queryData.isTop10 = true;
      queryData.filters.noOfDays = get(queryData, 'filters.noOfDays', 2);
      if (get(queryData, 'filters.symbols', []).length > 0) {
        delete queryData.filters.noOfDays;
      }
      return getIncreasedDowDailyPriceAndVolTheoryReport(queryData);

    case 'DDTDV':
      queryData.isTop10 = true;
      queryData.filters.noOfDays = get(queryData, 'filters.noOfDays', 2);
      if (get(queryData, 'filters.symbols', []).length > 0) {
        delete queryData.filters.noOfDays;
      }
      return getDecreasedDowDailyPriceAndVolTheoryReport(queryData);

    case 'TS':
      queryData.filters.date = moment()
        .tz('America/New_York')
        .add(selectedDateIndex, 'days')
        .format('YYYY-MM-DD');
      queryData.filters.trendingStockType = trendingStockType;
      return trendingStockType === 'ACTIVES'
        ? getTrendingStocksActivesReport(queryData)
        : getTrendingStockReport(queryData);

    default:
      return null;
  }
};

export const filtersNotToShow = (type: string) => {
  switch (type) {
    case 'AID':
      return ['date', 'noOfQuarters'];
    case 'DIL':
      return ['date', 'type'];
    case 'DIA':
      return ['endDate', 'startDate', 'type'];
    case 'DISS':
      return ['endDate', 'startDate'];
    case 'SDY':
      return ['date'];
    case 'DRMF':
      return [];
    case 'AMFR':
      return ['date'];
    case 'SSMF':
      return ['date'];
    case 'BFN':
      return ['date'];
    case 'SSL':
      return ['endDate', 'startDate'];
    case 'SSLC':
      return [];
    case 'SDL':
      return ['endDate', 'startDate'];
    case 'SAV':
      return ['endDate', 'startDate'];
    case 'SDLC':
      return [];
    case 'QTBS':
      return [];
    case 'ADMA':
      return ['date'];
    case 'ADUD':
      return ['date'];
    case 'AINB':
      return ['criterion', 'status'];
    case 'AIU':
      return ['noOfQuarters', 'multiStatus', 'criterion'];
    case 'AIO':
      return ['multiStatus'];
    case 'ITT':
      return [];
    case 'IS':
      return [];
    case 'DR':
      return [];
    case 'SR':
      return [];
    case 'TTNB':
      return ['isTop10', 'noOfQuarters', 'criterion', 'status'];
    case 'TTU':
      return ['isTop10', 'noOfQuarters', 'criterion'];
    case 'TTO':
      return ['isTop10', 'multiStatus'];
    case 'DTU':
      return ['isTop10'];
    case 'DTD':
      return ['isTop10'];
    case 'DDTU':
      return ['isTop10', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTD':
      return ['isTop10', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTUV':
      return ['isTop10', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTDV':
      return ['isTop10', 'isSP', 'isNasdaq', 'isDow'];
    case 'TS':
      return ['date', 'trendingStockType'];
    default:
      return null;
  }
};

export const NonClickAbleFilter = (type: string) => {
  switch (type) {
    case 'AID':
      return ['date', 'noOfQuarters'];
    case 'DIL':
      return ['date', 'type'];
    case 'DISS':
      return ['endDate', 'startDate'];
    case 'DIA':
      return ['endDate', 'startDate', 'type'];
    case 'SDY':
      return ['date'];
    case 'DRMF':
      return [];
    case 'AMFR':
      return [];
    case 'SSMF':
      return [];
    case 'BFN':
      return [];
    case 'SSL':
      return ['endDate', 'startDate'];
    case 'SSLC':
      return [];
    case 'SDL':
      return ['endDate', 'startDate'];
    case 'SAV':
      return ['status'];
    case 'SDLC':
      return [];
    case 'QTBS':
      return [];
    case 'ADMA':
      return ['date', 'isNasdaq', 'isDow'];
    case 'ADUD':
      return ['date'];
    case 'AINB':
      return ['criterion', 'status'];
    case 'AIU':
      return ['noOfQuarters', 'multiStatus', 'criterion'];
    case 'AIO':
      return ['multiStatus'];
    case 'ITT':
      return [];
    case 'IS':
      return [];
    case 'DR':
      return [];
    case 'SR':
      return ['category'];
    case 'TTNB':
      return ['isTop10', 'noOfQuarters', 'criterion', 'status'];
    case 'TTU':
      return ['isTop10', 'noOfQuarters', 'criterion'];
    case 'TTO':
      return ['isTop10', 'multiStatus'];
    case 'DTU':
      return ['isTop10', 'noOfWeeks', 'isSP', 'isNasdaq', 'isDow'];
    case 'DTD':
      return ['isTop10', 'noOfWeeks', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTU':
      return ['isTop10', 'noOfDays', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTD':
      return ['isTop10', 'noOfDays', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTUV':
      return ['isTop10', 'noOfDays', 'sortByVol', 'isSP', 'isNasdaq', 'isDow'];
    case 'DDTDV':
      return ['isTop10', 'noOfDays', 'sortByVol', 'isSP', 'isNasdaq', 'isDow'];
    case 'TS':
      return ['date', 'trendingStockType', 'isSP', 'isNasdaq', 'isDow'];
    default:
      return null;
  }
};

const getPreviousQuarter = (currentQuarter) => {
  const previousQuarterYear =
    currentQuarter[1] === 'Q1' ? +currentQuarter[0] - 1 : +currentQuarter[0];
  let previousQuarterQ = '';
  switch (currentQuarter[1]) {
    case 'Q1':
      previousQuarterQ = 'Q4';
      break;
    case 'Q2':
      previousQuarterQ = 'Q1';
      break;
    case 'Q3':
      previousQuarterQ = 'Q2';
      break;
    case 'Q4':
      previousQuarterQ = 'Q3';
      break;
    default:
      break;
  }
  return [previousQuarterYear, previousQuarterQ];
};

const getATNBTitle = (selectedQuarter) => {
  const currentQuarter = selectedQuarter
    ? selectedQuarter.split('_')
    : ['', ''];
  const prevviousQ = getPreviousQuarter(currentQuarter);
  return ` REPORT OF ALL INSTITUTIONS WHO BOUGHT NEW STOCKS DURING ${currentQuarter[1]}-${currentQuarter[0]} ONLY THAT DID NOT EXIST SINCE ${prevviousQ[1]}-${prevviousQ[0]}`;
};

const getTTNBTitle = (selectedQuarter) => {
  const currentQuarter = selectedQuarter
    ? selectedQuarter.split('_')
    : ['', ''];
  const prevviousQ = getPreviousQuarter(currentQuarter);
  return `ALL TOP-10 HEDGE FUNDS WHICH BOUGHT NEW STOCKS DURING ${currentQuarter[1]}-${currentQuarter[0]} ONLY THAT DID NOT EXIST SINCE ${prevviousQ[1]}-${prevviousQ[0]}`;
};
export const getReportTitle = (
  code,
  symbols,
  lastUpdatedTime,
  criterion,
  startDate,
  endDate,
  selectedQuarter,
  status,
) => {
  switch (code) {
    case 'AID':
      return lastUpdatedTime
        ? `All Institutions Report as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm:ss A)')}`
        : `All Institutions Report`;
    case 'SDY':
      return lastUpdatedTime
        ? `Latest SC 13D/G transactions of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY')} as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('hh:mm A')} `
        : `Latest SC 13D/G transactions`;
    case 'DRMF':
      return lastUpdatedTime
        ? `Latest Mutual Funds transactions of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY')} as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('hh:mm A')} `
        : `Latest Mutual Fund transactions`;
    case 'AMFR':
      return `Mutual Fund transactions`;
    case 'BFN':
      return `By Fund Name`;
    case 'SSMF':
      return `SYMBOL SUMMARY FOR ${symbols}`;
    case 'SSL':
      return lastUpdatedTime
        ? `All SYMBOL SUMMARY from ${moment(startDate)
            //.tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm A )')} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate)
                  //.tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All SYMBOL SUMMARY`;
    case 'SDL':
      return lastUpdatedTime
        ? `All SEC 13 D/G transactions from ${moment(startDate).format(
            'MMMM DD, YYYY ( hh:mm A )',
          )} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All SEC 13 D/G transactions `;
    case 'SAV':
      return `${
        status === 'INCREASED'
          ? 'Latest Top Institutional Buys'
          : 'Latest Top Institutional Sells'
      }`;
    case 'QTBS':
      return `Quarterly Institutional Buys & Sells`;
    case 'ADMA':
      return (
        <>
          ALL STOCKS PRICES MOVING AVERAGES
          <br />
          Sorted by #Institution in descending order
        </>
      );
    case 'ADUD':
      return `STOCKS WHICH ARE BETWEEN $0 AND $1 ONLY COMPARED AGAINST INSTITUTIONS,TOP-10-HEDGE FUNDS`;
    case 'DIL':
      return lastUpdatedTime
        ? `Latest Insider transactions of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY')} as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('hh:mm A')} `
        : `Latest Insider transactions`;
    case 'DISS':
      return lastUpdatedTime || startDate || endDate
        ? `All Insider SUMMARY from ${moment(startDate)
            //.tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm A )')} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime)
                  .tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate)
                  //.tz('America/New_York')
                  .format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All Insider SUMMARY`;
    case 'DIA':
      return lastUpdatedTime || startDate || endDate
        ? `ALL Insider transactions from ${moment(startDate).format(
            'MMMM DD, YYYY ( hh:mm A )',
          )} to ${
            moment(endDate).isSame(new Date(), 'day')
              ? moment(lastUpdatedTime).format('MMMM DD, YYYY ( hh:mm A )')
              : moment(endDate).format('MMMM DD, YYYY ( hh:mm A )')
          } `
        : `All Insider transactions `;
    case 'TS':
      return lastUpdatedTime
        ? `Trending Stocks Report as of ${moment(lastUpdatedTime)
            .tz('America/New_York')
            .format('MMMM DD, YYYY ( hh:mm:ss A)')}`
        : `Trending Stocks Report`;
    case 'DTU':
      return `TS WEEKLY UPTREND`;
    case 'DTD':
      return `TS WEEKLY DOWNTREND`;
    case 'DDTU':
      return `TS DAILY Price UPTREND`;
    case 'DDTD':
      return `TS DAILY Price DOWNTREND`;
    case 'DDTUV':
      return `TS DAILY Price & Volume UPTREND`;
    case 'DDTDV':
      return `TS DAILY Price & Volume DOWNTREND`;
    case 'AINB':
      return getATNBTitle(selectedQuarter);
    case 'AIU':
      return `REPORT OF ALL INSTITUTIONS ${
        criterion === 'BOTH' ? 'BOTH THEIR COUNTS and SHARES ' : criterion
      } CONSISTENTLY INCREASED`;
    case 'AIO':
      return `REPORT OF ALL INSTITUTES INCREASED AND REDUCED COUNTS, SHARES SINCE LAST 2 QUARTERS`;
    case 'ITT':
      return `TOP - INSTITUTION/HEDGE FUND PORTFOLIO DETAILED SUMMARY REPORT`;
    case 'IS':
      return `SPECIAL INSTITUTION/HEDGE FUND PORTFOLIO DETAILED SUMMARY REPORT`;
    case 'DR':
      return `Dividends Summary Report `;
    case 'SR':
      return `Sector wise allocation Report`;
    case 'TTNB':
      return getTTNBTitle(selectedQuarter);
    case 'TTU':
      return `TOP-10 HEDGE CONSISTENTLY INCREASED  ${
        criterion === 'BOTH' ? 'BOTH THEIR COUNTS and SHARES ' : criterion
      } SINCE LAST 2QS`;
    case 'TTO':
      return `REPORT OF BloomBerg-CONSISTENTLY INCREASED PREVIOUS 2 QUARTERS`;
    default:
      return '';
  }
};

export const filterFields = (code) => {
  const fields = [
    {
      name: 'minInvestedAmt',
      placeholder: 'Min Invested Amount',
      label: 'Min Invested Amount',
      allowDecimal: true,
      size: 12,
      type: 'minStudentInput',
      visible: code === 'student',
    },
    {
      name: 'maxInvestedAmt',
      placeholder: 'Max Invested Amount',
      label: 'Max Invested Amount',
      allowDecimal: true,
      size: 12,
      type: 'maxStudentInput',
      visible: code === 'student',
    },
    {
      name: 'minInvestedAmtReturns',
      placeholder: 'Min Invested Amt Returns',
      label: 'Min Invested Amt Returns',
      allowDecimal: true,
      size: 12,
      type: 'minStudentInput',
      visible: code === 'student',
    },
    {
      name: 'maxInvestedAmtReturns',
      placeholder: 'Max Invested Amt Returns',
      label: 'Max Invested Amt Returns',
      allowDecimal: true,
      size: 12,
      type: 'maxStudentInput',
      visible: code === 'student',
    },
    {
      name: 'minInvestedReturnsPerc',
      placeholder: 'Min Invested Returns Perc',
      label: 'Min Invested Returns Perc',
      allowDecimal: true,
      size: 12,
      type: 'minStudentInput',
      visible: code === 'student',
    },
    {
      name: 'maxInvestedReturnsPerc',
      placeholder: 'Max Invested Returns Perc',
      label: 'Max Invested Returns Perc',
      allowDecimal: true,
      size: 12,
      type: 'maxStudentInput',
      visible: code === 'student',
    },
    {
      name: 'minTotalPFvalue',
      placeholder: 'Min Total PF value',
      label: 'Min Total PF value',
      allowDecimal: true,
      size: 12,
      type: 'minStudentInput',
      visible: code === 'student',
    },
    {
      name: 'maxTotalPFvalue',
      placeholder: 'Max Total PF value',
      label: 'Max Total PF value',
      allowDecimal: true,
      size: 12,
      type: 'maxStudentInput',
      visible: code === 'student',
    },
    {
      name: 'minTotalPFReturnsPerc',
      placeholder: 'Min Total PF Returns Perc',
      label: 'Min Total PF Returns Perc',
      allowDecimal: true,
      size: 12,
      type: 'minStudentInput',
      visible: code === 'student',
    },
    {
      name: 'maxTotalPFReturnsPerc',
      placeholder: 'Max Total PF Returns Perc',
      label: 'Max Total PF Returns Perc',
      allowDecimal: true,
      size: 12,
      type: 'maxStudentInput',
      visible: code === 'student',
    },
    {
      name: 'symbols',
      placeholder: 'Enter Symbol',
      label: 'Enter Symbol',
      visible: code !== 'SR' && code !== 'student',
      type: 'symbols',
      size: 24,
    },
    {
      name: 'institutions',
      placeholder: 'Enter Institution',
      label: 'Enter Institution',
      visible: code === 'SDY' || code === 'SDL',
      type: 'ciks',
      size: 24,
    },
    {
      name: 'insiderName',
      placeholder: 'Enter Insider Name',
      label: 'Enter Insider Name',
      visible: code === 'DIL' || code === 'DIA',
      type: 'insider',
      size: 24,
    },
    {
      name: 'insiderTitle',
      placeholder: 'Enter Insider Title',
      label: 'Enter Insider Title',
      visible: code === 'DIL' || code === 'DIA',
      type: 'insiderTitle',
      size: 24,
    },
    {
      name: 'isTenPercent',
      placeholder: 'Is 10% Owner',
      label: 'Select Is 10% Owner',
      visible: code === 'DIL' || code === 'DIA',
      type: 'isTenPercent',
      size: 24,
    },
    {
      name: 'minInsiderTransactionValue',
      placeholder: 'Min Transaction value',
      label: 'Min Transaction value',
      allowDecimal: false,
      visible: code === 'DIL' || code === 'DIA',
      size: 12,
      type: 'minInsiderTransactionValue',
    },
    {
      name: 'maxInsiderTransactionValue',
      placeholder: 'Max Transaction value',
      label: 'Max Transaction value',
      allowDecimal: false,
      visible: code === 'DIL' || code === 'DIA',
      size: 12,
      type: 'maxInsiderTransactionValue',
    },
    {
      name: 'multiSector',
      placeholder: 'Sector',
      size: 12,
      visible: code !== 'student' && code !== 'earningsCalender',
      type: 'multiSector',
      multiple: true,
    },
    {
      name: 'excludeMultiSector',
      placeholder: 'Exclude Sector',
      size: 12,
      visible: code !== 'student' && code !== 'earningsCalender',
      type: 'excludeMultiSector',
      multiple: true,
    },
    {
      name: 'multiIndustry',
      placeholder: 'Industry',
      size: 12,
      visible: !codeArray.includes(code),
      type: 'multiIndustry',
      multiple:
        code === 'TS' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'AID',
    },
    {
      name: 'country',
      placeholder: 'Country',
      label: 'Country',
      allowDecimal: false,
      visible: code === 'AID',
      size: 12,
      type: 'country',
    },
    {
      name: 'multiStatus',
      placeholder: 'Status',
      data: statusOptions,
      size: 12,
      type: 'selectObjectField',
      multiple: true,
      visible:
        code === 'TS' ||
        code === 'IS' ||
        code === 'ITT' ||
        code === 'AIO' ||
        code === 'AID' ||
        code === 'SDL' ||
        code === 'SDLC' ||
        code === 'ITT' ||
        code === 'SDY',
    },
    {
      name: 'multiMarketCap',
      placeholder: 'Market Cap',
      data: marketCapOptions,
      size: 12,
      type: 'selectObjectField',
      multiple: true,
      visible: code === 'TS' || code === 'SAV',
    },
    {
      name: 'minOwnership',
      placeholder: 'Min % Own',
      label: 'Min % Own',
      allowDecimal: true,
      visible: code === 'SDY' || code === 'SDL',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minCountChange',
      placeholder: 'Min % Count Change',
      label: 'Min % Count Change',
      allowDecimal: true,
      size: 12,
      type: 'minCountChange',
      visible:
        code === 'SDY' ||
        code === 'AIO' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'TTO' ||
        code === 'AIU' ||
        code === 'AID' ||
        code === 'TTU' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'SAV' ||
        code === 'SDL',
    },

    {
      name: 'maxCountChange',
      placeholder: 'Max % Count Change',
      label: 'Max % Count Change',
      allowDecimal: true,
      size: 12,
      type: 'maxCountChange',
      visible:
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'AIU' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'TTU' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'AID',
    },
    {
      name: 'minPossibleGrowth',
      placeholder: 'Min % Possible Growth',
      label: 'Min % Possible Growth',
      allowDecimal: true,
      size: 12,
      type: 'minPossibleGrowth',
      visible: code === 'AID',
    },
    {
      name: 'maxPossibleGrowth',
      placeholder: 'Max % Possible Growth',
      label: 'Max % Possible Growth',
      allowDecimal: true,
      size: 12,
      type: 'maxPossibleGrowth',
      visible: code === 'AID',
    },
    {
      name: 'filerName',
      placeholder: 'Fund Name',
      size: 12,
      type: 'fundName',
      multiple: true,
      visible: code === 'DRMF' || code === 'SSMF' || code === 'AMFR',
    },
    {
      name: 'seriesName',
      placeholder: 'series Name',
      size: 12,
      type: 'seriesName',
      multiple: true,
      visible: code === 'DRMF' || code === 'SSMF' || code === 'AMFR',
    },
    {
      name: 'minSharesChange',
      placeholder: `Min % Shares Change (${
        getLastQtrByIdx(2).split('_')[1] +
        '-' +
        getLastQtrByIdx(1).split('_')[1]
      })`,
      label: `Min % Shrs Chg (${
        getLastQtrByIdx(2).split('_')[1] +
        '-' +
        getLastQtrByIdx(1).split('_')[1]
      })`,
      size: 12,
      type: 'minSharesChange',
      allowDecimal: true,
      visible:
        code === 'AIO' ||
        code === 'TS' ||
        code === 'SDY' ||
        code === 'TTO' ||
        code === 'AIU' ||
        code === 'AID' ||
        code === 'IS' ||
        code === 'TTU' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'SAV' ||
        code === 'SDL',
    },
    {
      name: 'maxSharesChange',
      placeholder: `Max % Shares Change (${
        getLastQtrByIdx(2).split('_')[1] +
        '-' +
        getLastQtrByIdx(1).split('_')[1]
      })`,
      label: `Max % Shrs Chg (${
        getLastQtrByIdx(2).split('_')[1] +
        '-' +
        getLastQtrByIdx(1).split('_')[1]
      })`,
      size: 12,
      type: 'maxSharesChange',
      allowDecimal: true,
      visible:
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'AIU' ||
        code === 'AID' ||
        code === 'TTU' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'IS',
    },
    {
      name: 'minSharesChangePer',
      placeholder: 'Min Shares % Change',
      label: 'Min Shares % Change',
      size: 12,
      type: 'inputField',
      allowDecimal: true,
      visible: code === 'SDY' || code === 'SDL',
    },
    {
      name: 'minPerChange',
      placeholder: 'Min % Change',
      label: 'Min % Change',
      allowDecimal: true,
      size: 12,
      type: 'inputField',
      visible: code === 'SDLC',
    },
    {
      name: 'minPercentage',
      placeholder: 'Min % of Allocation',
      label: 'Min % of Allocation',
      allowDecimal: true,
      size: 12,
      type: 'minPercent',
      visible: code === 'DRMF' || code === 'AMFR',
    },
    {
      name: 'maxPercentage',
      placeholder: 'Max % of Allocation',
      label: 'Max % of Allocation',
      allowDecimal: true,
      size: 12,
      type: 'maxPercent',
      visible: code === 'DRMF' || code === 'AMFR',
    },

    {
      name: 'minGrowth',
      placeholder: 'Min Growth %',
      label: 'Min Growth %',
      allowDecimal: true,
      size: 12,
      type: 'inputField',
      visible: false,
    },
    {
      name: 'minYTD',
      placeholder: 'Min YTD',
      label: 'Min YTD',
      allowDecimal: true,
      visible:
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'AINB' ||
        code === 'TTU' ||
        code === 'DRMF' ||
        code === 'AMFR' ||
        code === 'ADUD' ||
        code === 'AIO' ||
        code === 'TTNB' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'maxYTD',
      placeholder: 'Max YTD',
      label: 'Max YTD',
      allowDecimal: true,
      visible:
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'AINB' ||
        code === 'TTU' ||
        code === 'DRMF' ||
        code === 'AMFR' ||
        code === 'ADUD' ||
        code === 'AIO' ||
        code === 'TTNB' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'min1Yr',
      placeholder: 'Min 1Yr %',
      label: 'Min 1Yr %',
      allowDecimal: true,
      visible:
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'AINB' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'AIO' ||
        code === 'TTNB' ||
        code === 'DRMF' ||
        code === 'AMFR' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'max1Yr',
      placeholder: 'Max 1Yr %',
      label: 'Max 1Yr %',
      allowDecimal: true,
      visible:
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'AINB' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'AIO' ||
        code === 'TTNB' ||
        code === 'DRMF' ||
        code === 'AMFR' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'min5Yr',
      placeholder: 'Min 5Yr %',
      label: 'Min 5Yr %',
      allowDecimal: true,
      visible:
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'AINB' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'TTNB' ||
        code === 'DRMF' ||
        code === 'AMFR' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',
      size: 12,
      type: 'inputField',
    },
    {
      name: 'minPrice',
      placeholder: 'Min Price',
      label: 'Min Price',
      allowDecimal: true,
      visible:
        code === 'TS' ||
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'DIL' ||
        code === 'AINB' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'TTNB' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'DR' ||
        code === 'AIU' ||
        code === 'DRMF' ||
        code === 'earningsCalender' ||
        code === 'AMFR' ||
        code === 'DIA',
      size: 12,
      type: 'minPrice',
    },
    {
      name: 'maxPrice',
      placeholder: 'Max Price',
      label: 'Max Price',
      allowDecimal: true,
      visible:
        code === 'TS' ||
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        code === 'DIL' ||
        code === 'AINB' ||
        code === 'earningsCalender' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'TTNB' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'DR' ||
        code === 'AIU' ||
        code === 'DRMF' ||
        code === 'AMFR' ||
        code === 'DIA',
      size: 12,
      type: 'maxPrice',
    },
    {
      name: 'minInstCount',
      placeholder: 'Min Inst count',
      label: 'Min Inst count',
      allowDecimal: false,
      visible:
        code === 'TS' ||
        code === 'IS' ||
        code === 'SDY' ||
        code === 'SSL' ||
        code === 'DISS' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        // code === 'DIL' ||
        code === 'AINB' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'TTNB' ||
        code === 'AIO' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',

      size: 12,
      type: 'minInstCount',
    },
    {
      name: 'maxInstCount',
      placeholder: 'Max Inst count',
      label: 'Max Inst count',
      allowDecimal: false,
      visible:
        code === 'TS' ||
        code === 'AID' ||
        code === 'ADMA' ||
        code === 'DTU' ||
        code === 'DTD' ||
        code === 'DDTU' ||
        code === 'DDTD' ||
        code === 'DDTUV' ||
        code === 'DDTDV' ||
        // code === 'DIL' ||
        code === 'SDL' ||
        code === 'SAV' ||
        code === 'AINB' ||
        code === 'IS' ||
        code === 'TTU' ||
        code === 'ADUD' ||
        code === 'AIO' ||
        code === 'TTNB' ||
        code === 'TTO' ||
        code === 'ITT' ||
        code === 'AIU',
      size: 12,
      type: 'maxInstCount',
    },
    {
      name: 'minPrevQInstCount',
      placeholder: 'Min Prev Q Inst count',
      label: 'Min Prev Q Inst count',
      allowDecimal: false,
      visible: code === 'SDL',
      size: 12,
      type: 'minPrevInstCount',
    },
    {
      name: 'maxPrevQInstCount',
      placeholder: 'Max Prev Q Inst count',
      label: 'Max Prev Q Inst count',
      allowDecimal: false,
      visible: code === 'SDL',
      size: 12,
      type: 'maxPrevInstCount',
    },
    {
      name: 'minInstShares',
      placeholder: 'Min Inst Shares',
      label: 'Min Inst Shares',
      allowDecimal: false,
      visible: code === 'SAV' || code === 'ADMA' || code === 'SSL',
      size: 12,
      type: 'minInstShares',
    },
    {
      name: 'maxInstShares',
      placeholder: 'Max Inst Shares',
      label: 'Max Inst Shares',
      allowDecimal: false,
      visible: code === 'SAV',
      size: 12,
      type: 'maxInstShares',
    },
    {
      name: 'minAproxTxnValue',
      placeholder: 'Min Approx. trxn value',
      label: 'Min Approx. trxn value',
      allowDecimal: false,
      visible: code === 'SAV',
      size: 12,
      type: 'minAproxTxnValue',
    },
    {
      name: 'maxAproxTxnValue',
      placeholder: 'Max Approx. trxn value',
      label: 'Max Approx. trxn value',
      allowDecimal: false,
      visible: code === 'SAV',
      size: 12,
      type: 'maxAproxTxnValue',
    },
    {
      name: 'minAum',
      placeholder: 'Min AUM',
      label: 'Min AUM',
      allowDecimal: false,
      visible: code === 'SDL',
      size: 12,
      type: 'minAum',
    },
    {
      name: 'maxAum',
      placeholder: 'Max AUM',
      label: 'Max AUM',
      allowDecimal: false,
      visible: code === 'SDL',
      size: 12,
      type: 'maxAum',
    },
    {
      name: 'hedgeCount',
      placeholder: 'Hedge Count',
      label: 'Hedge Count',
      allowDecimal: false,
      visible: code === 'SDL',
      size: 12,
      type: 'hedgeCount',
    },
    // {
    //   name: 'minMarketCap',
    //   placeholder: 'Min MarketCap',
    //   label: 'Min MarketCap',
    //   allowDecimal: false,
    //   visible: code === 'SDL',
    //   size: 12,
    //   type: 'minMarketCap',
    // },
    // {
    //   name: 'maxMarketCap',
    //   placeholder: 'Max MarketCap',
    //   label: 'Max MarketCap',
    //   allowDecimal: false,
    //   visible: code === 'SDL',
    //   size: 12,
    //   type: 'maxMarketCap',
    // },
    {
      name: 'minLastQ2InstCount',
      placeholder: 'Min Prev Q Inst count',
      label: 'Min Prev Q Inst count',
      allowDecimal: false,
      visible: code === 'AID',
      size: 12,
      type: 'minLastQ2InstCount',
    },
    {
      name: 'maxLastQ2InstCount',
      placeholder: 'Max Prev Q Inst count',
      label: 'Max Prev Q Inst count',
      allowDecimal: false,
      visible: code === 'AID',
      size: 12,
      type: 'maxLastQ2InstCount',
    },
    {
      name: 'minTop10HedgeFundsIn_NC_NB_CNT',
      placeholder: 'Min Top-10 HF NB/INCR/NC',
      label: 'Min Top-10 HF NB/INCR/NC',
      visible: code === 'AID',
      size: 12,
      type: 'minTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      name: 'maxTop10HedgeFundsIn_NC_NB_CNT',
      placeholder: 'Max Top-10 HF NB/INCR/NC',
      label: 'Max Top-10 HF NB/INCR/NC',
      visible: code === 'AID',
      size: 12,
      type: 'maxTop10HedgeFundsIn_NC_NB_CNT',
    },
    {
      name: 'minTop10InstIn_NC_NB_CNT',
      placeholder: 'Min Top-10 Inst NB/INCR/NC',
      label: 'Min Top-10 Inst NB/INCR/NC',
      visible: code === 'AID',
      size: 12,
      type: 'minTop10InstIn_NC_NB_CNT',
    },
    {
      name: 'maxTop10InstIn_NC_NB_CNT',
      placeholder: 'Max Top-10 Inst NB/INCR/NC',
      label: 'Max Top-10 Inst NB/INCR/NC',
      visible: code === 'AID',
      size: 12,
      type: 'maxTop10InstIn_NC_NB_CNT',
    },
    {
      name: 'minSec13dCount',
      placeholder: 'Min SEC 13D/G Count',
      label: 'Min SEC 13D/G Count',
      allowDecimal: false,
      visible: code === 'TS' || code === 'SDL' || code === 'SAV',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minPerReturn',
      placeholder: 'Min % Return',
      label: 'Min % Return',
      allowDecimal: true,
      visible: code === 'TS',
      size: 12,
      type: 'inputFieldPositive',
    },

    {
      name: 'minSecInstCount',
      placeholder: 'Min SEC Inst Count',
      label: 'Min SEC Inst Count',
      allowDecimal: false,
      visible: code === 'SSL',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minSecInsiderCount',
      placeholder: 'Min Sec Insider count',
      label: 'Min Sec Insider count',
      allowDecimal: false,
      visible: code === 'DISS',
      size: 12,
      type: 'inputFieldPositive',
    },
    {
      name: 'minOneDayChange',
      placeholder: 'Min 1 Day change',
      label: 'Min 1 Day change',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'minOneDayChange',
    },
    {
      name: 'maxOneDayChange',
      placeholder: 'Max 1 Day change',
      label: 'Max 1 Day change',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'maxOneDayChange',
    },
    {
      name: 'min1DMA',
      placeholder: 'Min 1 DMA',
      label: 'Min 1 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min1DMA',
    },
    {
      name: 'min5DMA',
      placeholder: 'Min 5 DMA',
      label: 'Min 5 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min5DMA',
    },
    {
      name: 'min10DMA',
      placeholder: 'Min 10 DMA',
      label: 'Min 10 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min10DMA',
    },
    {
      name: 'min20DMA',
      placeholder: 'Min 20 DMA',
      label: 'Min 20 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min20DMA',
    },
    {
      name: 'min50DMA',
      placeholder: 'Min 50 DMA',
      label: 'Min 50 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min50DMA',
    },
    {
      name: 'min100DMA',
      placeholder: 'Min 100 DMA',
      label: 'Min 100 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min100DMA',
    },
    {
      name: 'min150DMA',
      placeholder: 'Min 150 DMA',
      label: 'Min 150 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min150DMA',
    },
    {
      name: 'min200DMA',
      placeholder: 'Min 200 DMA',
      label: 'Min 200 DMA',
      allowDecimal: true,
      visible: code === 'ADMA',
      size: 12,
      type: 'min200DMA',
    },
    {
      name: 'minGain',
      placeholder: 'Min % Gain',
      label: 'Min % Gain',
      allowDecimal: true,
      visible: code === 'DTU',
      size: 12,
      type: 'minGain',
    },
    {
      name: 'maxGain',
      placeholder: 'Max % Gain',
      label: 'Max % Gain',
      allowDecimal: true,
      visible: code === 'DTU',
      size: 12,
      type: 'maxGain',
    },
    {
      name: 'minLoss',
      placeholder: 'Min % Loss',
      label: 'Min % Loss',
      allowDecimal: true,
      visible: code === 'DTD',
      size: 12,
      type: 'minGain',
    },
    {
      name: 'maxLoss',
      placeholder: 'Max % Loss',
      label: 'Max % Loss',
      allowDecimal: true,
      visible: code === 'DTD',
      size: 12,
      type: 'maxLoss',
    },
    {
      name: 'minDayGain',
      placeholder: 'Min % Gain',
      label: 'Min % Gain',
      allowDecimal: true,
      visible: code === 'DDTU' || code === 'DDTUV',
      size: 12,
      type: 'minDayGain',
    },
    {
      name: 'maxDayGain',
      placeholder: 'Max % Gain',
      label: 'Max % Gain',
      allowDecimal: true,
      visible: code === 'DDTU' || code === 'DDTUV',
      size: 12,
      type: 'maxDayGain',
    },
    {
      name: 'minDayLoss',
      placeholder: 'Min % Loss',
      label: 'Min % Loss',
      allowDecimal: true,
      visible: code === 'DDTD' || code === 'DDTDV',
      size: 12,
      type: 'minDayLoss',
    },
    {
      name: 'maxDayLoss',
      placeholder: 'Max % Loss',
      label: 'Max % Loss',
      allowDecimal: true,
      visible: code === 'DDTD' || code === 'DDTDV',
      size: 12,
      type: 'maxDayLoss',
    },
    {
      name: 'minVolGain',
      placeholder: 'Min % Gain',
      label: 'Min vol % Gain',
      allowDecimal: true,
      visible: code === 'DDTUV',
      size: 12,
      type: 'minVolGain',
    },
    {
      name: 'maxVolGain',
      placeholder: 'Max % Gain',
      label: 'Max Vol % Gain',
      allowDecimal: true,
      visible: code === 'DDTUV',
      size: 12,
      type: 'maxVolGain',
    },
    {
      name: 'minVolLoss',
      placeholder: 'Min % Loss',
      label: 'Min Vol % Loss',
      allowDecimal: true,
      visible: code === 'DDTDV',
      size: 12,
      type: 'minVolLoss',
    },
    {
      name: 'maxVolLoss',
      placeholder: 'Max % Loss',
      label: 'Max Vol % Loss',
      allowDecimal: true,
      visible: code === 'DDTDV',
      size: 12,
      type: 'maxVolLoss',
    },
    {
      name: 'fromDate',
      placeholder: 'From',
      label: 'From Date',
      visible: code === 'TS',
      size: 12,
      type: 'date',
    },
    {
      name: 'minVolDiff',
      placeholder: 'Min Vol Diff',
      label: 'Min Vol Diff',
      visible: code === 'TS',
      size: 12,
      type: 'minVolDiff',
    },
    {
      name: 'maxVolDiff',
      placeholder: 'Max Vol Diff',
      label: 'Max Vol Diff',
      visible: code === 'TS',
      size: 12,
      type: 'maxVolDiff',
    },
    {
      name: 'range',
      label: '"Date Range',
      visible:
        code === 'SSL' || code === 'SDL' || code === 'DISS' || code === 'DIA',
      size: 24,
      type: 'dateRange',
    },
  ];
  return fields.filter((field) => field.visible);
};

function getDisplayableQtr(qtrStr) {
  const qtrString = qtrStr.split('_');
  return qtrString[1] + '-' + qtrString[0];
}

export const NOTE = (trendingStockType, selectedQuarter, filterOptions) => ({
  TS:
    trendingStockType === 'ACTIVES'
      ? ' The Default Sort is based on the Current  volume'
      : 'The Default Sort is based on the Returns %  column ',
  SDY: 'The Default Sort is based on the Sec Reported date & time',
  SSL: 'The Default Sort is based on the SC 13D/G count',
  SDL: 'The Default Sort is based on the Sec Reported date & time',
  SAV: 'The Default Sort is based on the Approx. Trxn Value',
  DDTU: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  DDTUV: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  DDTD: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  DDTDV: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  AID: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  ADMA: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  ADUD: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  DTU: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  DTD: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  AINB: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  AIU:
    get(filterOptions, 'criterion', '') === 'COUNT'
      ? `The Default Sort is based on the ${getDisplayableQtr(
          selectedQuarter,
        )} Inst Counts`
      : `The Default Sort is based on the ${getDisplayableQtr(
          selectedQuarter,
        )} Inst Shares`,
  AIO: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  TTNB: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  TTU:
    get(filterOptions, 'criterion', '') === 'COUNT'
      ? `The Default Sort is based on the ${getDisplayableQtr(
          selectedQuarter,
        )} Inst Counts`
      : `The Default Sort is based on the ${getDisplayableQtr(
          selectedQuarter,
        )} Inst Shares`,
  TTO: `The Default Sort is based on the ${getDisplayableQtr(
    selectedQuarter,
  )} Inst Counts`,
  DRMF: 'The Default Sort is based on the Shares value',
  AMFR: 'The Default Sort is based on the Shares value',
  DIL: 'The Default Sort is based on the Sec Reported date & time',
  DIA: 'The Default Sort is based on the Sec Reported date & time',
  DISS: 'The Default Sort is based on the Sec Insiders count',
  QTBS: 'The Default Sort is based on the Approx. Trxn Value',
  ITT: 'The Default Sort is based on the Market Value',
  IS: 'The Default Sort is based on the Market Value',
  SR: 'The Default Sort is based on the Sector column',
});
