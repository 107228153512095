import React, { useEffect, useState } from 'react';

import { GoogleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Row, Col, Input, Form, Button, Checkbox } from 'antd';
import { Play, X } from 'lucide-react';
import { RouteComponentProps, Link, useLocation } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';

import { Tnc } from '../../components';
import { green, isStudentUrl } from '../../utils';
import styles from './style.module.scss';

interface Props extends RouteComponentProps<any> {
  username: string;
  onpromoCodeGChange: Function;
  setTncAccepted: Function;
  signUpClick: boolean;
  signUpFunction: Function;
  setAccessUrl: Function;
  setError: Function;
  showVerifyMessage: boolean;
  onFinish: Function;
  tncAccepted: boolean;
  isLoading: boolean;
  facebookSignIn: Function;
  isUserVerified: boolean;
  googleSignIn: Function;
  isSignUp: boolean;
  error: string;
}

// const IconFont = createFromIconfontCN({
//   scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js',
// });

const SignUpForm: React.FC<Props> = ({
  username,
  onpromoCodeGChange,
  setTncAccepted,
  setError,
  showVerifyMessage,
  signUpFunction,
  onFinish,
  setAccessUrl,
  tncAccepted,
  signUpClick,
  isLoading,
  facebookSignIn,
  isUserVerified,
  googleSignIn,
  isSignUp,
  error,
  history,
}) => {
  const videoUrl =
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success.mp4';
  const imageUrls = [
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/1.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/2.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/3.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/4.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/5.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/6.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/7.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/8.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/9.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/10.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/11.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/12.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/13.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/14.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/15.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/16.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/17.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/18.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/19.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/20.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/21.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/22.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/23.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/24.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/25.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/26.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/27.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/28.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/29.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/30.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/31.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/32.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/33.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/34.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/35.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/36.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/37.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/38.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/39.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/40.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/41.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/42.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/43.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/44.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/45.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/46.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/47.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/48.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/49.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/50.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/51.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/52.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/53.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/54.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/55.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/56.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/57.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/58.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/59.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/60.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/61.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/62.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/63.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/64.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/65.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/66.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/67.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/68.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/69.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/70.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/71.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/72.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/73.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/74.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/75.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/76.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/77.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/78.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/79.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/80.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/81.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/82.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/83.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/84.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/85.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/86.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/87.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/88.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/89.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/90.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/91.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/92.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/93.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/94.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/95.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/96.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/97.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/98.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/99.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/100.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/101.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/102.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/103.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/104.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/105.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/106.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/107.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/108.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/109.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/110.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/111.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/112.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/113.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/114.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/115.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/116.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/117.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/118.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/119.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/120.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/121.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/122.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/123.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/124.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/125.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/126.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/127.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/128.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/129.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/130.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/131.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/132.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/133.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/134.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/135.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/136.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/137.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/138.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/139.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/140.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/141.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/142.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/143.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/144.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/145.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/146.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/147.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/148.jpg',
    'https://thinksabio-asset.s3.us-east-1.amazonaws.com/success_img/149.jpg',
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    onpromoCodeGChange(promoCode);
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // Auto-slide every 3 seconds
    return () => clearInterval(interval);
  }, [currentIndex]);

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1,
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1,
    );
  };
  const prevPage =
    history && history.location && history.location.state
      ? history.location.state?.prevPage
      : null;
  console.log('history at signup page', history);
  const location = useLocation();
  const isPromo = location.state?.name?.properties?.name === 'promo';
  const [openTnc, setOpenTnc] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const [isPromoUser, setIsPromoUser] = useState<boolean>(false);
  const url = new URL(window.location.href);
  const promoCode = url.searchParams.get('promo');
  // const [signUpClick, setSignUpClicked] = useState<boolean>(false);
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const setToDefaultState = () => {
    signUpFunction(false);
  };

  const onCheckboxChange = (e) => {
    // setTncAccepted(e.target.checked);
    setIsPromoUser(!isPromoUser);
  };

  const isStudent = isStudentUrl();
  return (
    <div className={styles.container}>
      {/* <h3>{username ? 'VERIFY' : 'REGISTER'}</h3> */}
      <div className={styles.signupContent}>
        <div className={styles.signupContentImage}>
          <div
            style={{
              backgroundColor: '#21272C',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              padding: '2rem',
            }}
          >
            {/* TOP SECTION - 20% Height */}
            <div
              style={{
                marginTop: '20px',
                width: '100%',
                height: '30%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {!isOpen ? (
                <div
                  style={{
                    width: isMobile ? '90%' : '360px',
                    height: isMobile ? '250px' : '100%',
                    backgroundSize: 'contain',
                    backgroundImage:
                      'url("https://thinksabio-asset.s3.us-east-1.amazonaws.com/ProudThinkSabiens.jpg")',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <button
                    style={{
                      width: '4rem',
                      height: '4rem',
                      borderRadius: '50%',
                      backgroundColor: '#22c55e',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'none',
                      cursor: 'pointer',
                      opacity: 0.9,
                    }}
                    onClick={() => setIsOpen(true)}
                  >
                    <Play size={40} color="white" />
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    width: '40%',
                    position: 'relative',
                    height: '100%',
                  }}
                >
                  <video
                    controls
                    autoPlay
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      height: '100%',
                    }}
                  >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <button
                    onClick={() => setIsOpen(false)}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                      backgroundColor: 'red',
                      color: 'white',
                      border: 'none',
                      borderRadius: '50%',
                      width: '2rem',
                      height: '2rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <X size={16} />
                  </button>
                </div>
              )}
            </div>

            {/* BOTTOM SECTION - 80% Height */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1400px',
                alignItems: 'center',
                justifyContent: 'center',
                height: '70%',
                textAlign: isMobile ? 'center' : 'left',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  width: isMobile ? '100%' : '80%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#21272C',
                  height: '70%',
                  position: 'relative',
                }}
              >
                {/* Left Arrow */}
                <button
                  onClick={goToPrev}
                  style={{
                    position: 'absolute',
                    left: '10%',
                    backgroundColor: '#1abd50',
                    border: 'none',
                    color: 'white',
                    fontSize: '1.5rem',
                    padding: '10px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = '#1abd50')
                  }
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = 'transparent')
                  }
                >
                  &#8592;
                </button>

                {/* Image */}
                <img
                  src={imageUrls[currentIndex]}
                  alt="Smart Money"
                  style={{
                    width: isMobile ? '100%' : '100%',
                    height: 'auto',
                    transform: isMobile ? 'scale(0.5)' : 'scale(0.4)',
                    borderRadius: '8px',
                  }}
                />

                {/* Right Arrow */}
                <button
                  onClick={goToNext}
                  style={{
                    position: 'absolute',
                    right: '10%',
                    backgroundColor: '#1abd50',
                    border: 'none',
                    color: 'white',
                    fontSize: '1.5rem',
                    padding: '10px 10px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                  onMouseOut={(e) =>
                    (e.target.style.backgroundColor = '#1abd50')
                  }
                  onMouseOver={(e) =>
                    (e.target.style.backgroundColor = 'transparent')
                  }
                >
                  &#8594;
                </button>
              </div>

              {/* Right Section - Text */}
              <h2
                style={{
                  fontSize: isMobile ? '2rem' : '3rem',
                  fontWeight: 'bold',
                  marginTop: isMobile ? '1rem' : '20px',
                  color: 'white',
                  lineHeight: '1',
                  textAlign: isMobile ? 'center' : 'left',
                }}
              >
                <span style={{ color: '#22c55e' }}>&ldquo;ThinkSabio </span>
                <span
                  style={{
                    fontSize: isMobile ? '1.5rem' : '2rem',
                    color: 'white',
                  }}
                >
                  follows
                </span>
                <span style={{ color: 'white' }}> Smart Money&rdquo;</span>
              </h2>
            </div>
          </div>
        </div>
        <div className={styles.signupContentText}>
          <div className={styles.logotext}>
            <h4>
              {signUpClick && !showVerifyMessage ? (
                <div>
                  <span
                    onClick={() => setToDefaultState()}
                    className={styles.vGoBackBtn}
                  >
                    <ArrowLeftOutlined />
                  </span>
                </div>
              ) : (
                <Link to={prevPage ? prevPage : '/'}>
                  {' '}
                  <ArrowLeftOutlined />{' '}
                </Link>
              )}
            </h4>
            <div className={styles.logoImage}>
              <Link to="/">
                {' '}
                <img
                  alt="logo"
                  src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
                />
              </Link>
            </div>
          </div>
          <div className={styles.formContentText}>
            <div className={styles.formContentTextBox}>
              {!username && !showVerifyMessage && (
                <Form
                  style={{ color: 'white !important' }}
                  {...layout}
                  name="sign-up"
                  onFinish={onFinish}
                  onValuesChange={() => setError('')}
                  initialValues={{
                    agreement: true,
                    Email: '',
                    promoCode: promoCode,
                  }}
                >
                  {isStudent ? (
                    ''
                  ) : (
                    <>
                      <Form.Item>
                        <Row span={24} justify="end">
                          <Col
                            style={{ marginTop: isEmail ? '' : '150px' }}
                            span={24}
                          >
                            <Button
                              size="large"
                              style={{
                                width: '100%',
                              }}
                              className={styles.googleBtn}
                              onClick={googleSignIn}
                            >
                              <GoogleOutlined />
                              Sign up with Google
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                      {!promoCode ? (
                        <Checkbox
                          checked={isPromoUser || isPromo || promoCode}
                          onChange={onCheckboxChange}
                        >
                          Have Promo Code ?
                        </Checkbox>
                      ) : (
                        ''
                      )}
                      {(isPromo || isPromoUser) && !promoCode ? (
                        <Form.Item
                          name="promoCode"
                          label="Promo Code"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Promo Code!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Promo Code"
                            onChange={(e) => onpromoCodeGChange(e.target.value)} // Call onPromoCodeChange function on value change
                          />
                        </Form.Item>
                      ) : (
                        ''
                      )}
                      <p className={styles.ortext}>
                        <hr />
                        <span style={{ paddingTop: '10px' }}>OR</span>
                        <hr />
                      </p>
                    </>
                  )}
                  {
                    <>
                      <Col hidden={isEmail} span={24}>
                        <Button
                          size="large"
                          style={{
                            width: '100%',
                          }}
                          className={styles.mailBtn}
                          onClick={() => {
                            setIsEmail(true);
                          }}
                        >
                          Create Account
                        </Button>
                      </Col>
                      <div hidden={!isEmail}>
                        <Form.Item
                          name="firstName"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter First Name!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter First Name"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          name="lastName"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Last Name!',
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter Last Name"
                            autoComplete="off"
                          />
                        </Form.Item>
                        <Form.Item
                          // hasFeedback
                          name="Email"
                          label="Email"
                          rules={[
                            {
                              type: 'email',
                              required: true,
                              message: 'Please enter valid Email!',
                            },
                          ]}
                        >
                          <Input
                            placeholder={
                              isStudent
                                ? 'Please Enter Your Email Id'
                                : 'xyz@example.com'
                            }
                            autoComplete="false"
                          />
                        </Form.Item>
                        <Form.Item
                          name="Password"
                          label="Password"
                          className={styles.passwordField}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                let regex =
                                  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,16}$/;
                                if (!value) {
                                  return Promise.reject('Password is required');
                                }
                                if (!regex.test(value)) {
                                  return Promise.reject(
                                    'Password must be 8-16 characters and contain both numbers and letters/special characters.',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            placeholder="Enter Password"
                            autoComplete="off"
                            visibilityToggle={true}
                          />
                        </Form.Item>
                        {!promoCode ? (
                          <Checkbox
                            checked={isPromoUser || isPromo || promoCode}
                            onChange={onCheckboxChange}
                          >
                            Have Promo Code ?
                          </Checkbox>
                        ) : (
                          ''
                        )}
                        {(isPromo || isPromoUser) && !promoCode ? (
                          <Form.Item
                            name="promoCode"
                            label="Promo Code"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter Promo Code!',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Promo Code" />
                          </Form.Item>
                        ) : (
                          ''
                        )}
                        <Col span={24}>
                          <Form.Item>
                            <span
                              style={{
                                color: 'var(--whitecolor)',
                                marginRight: '10px',
                              }}
                            >
                              By clicking Sign Up, I agree to the
                            </span>
                            <Link
                              to="/tnc"
                              // onClick={() => setOpenTnc(true)}
                              className={styles.tnc}
                            >
                              Terms and Conditions & Privacy Policy
                            </Link>
                          </Form.Item>
                        </Col>
                        <Form.Item>
                          <Button
                            className={styles.signUp}
                            type="primary"
                            htmlType="submit"
                            style={{ width: '100%' }}
                            loading={isLoading}
                            size="large"
                          >
                            Sign Up
                          </Button>
                        </Form.Item>
                        <p style={{ color: 'red', marginBottom: '15px' }}>
                          {error}
                        </p>
                      </div>
                    </>
                  }
                  <p className={styles.anAccount}>
                    {isStudent ? (
                      <>
                        Issue with the signup?&nbsp;
                        <Link to="/contact-us">Contact Us</Link>
                      </>
                    ) : (
                      ''
                    )}
                    &nbsp;&nbsp;&nbsp;&nbsp; Already have an account?
                    <Link to="/login"> Login</Link>
                  </p>
                  <p className={styles.anAccount}></p>
                </Form>
              )}

              {!isSignUp && (
                <>
                  {isLoading && (
                    <div className={styles.loaderStyle}>
                      <BounceLoader
                        color={green}
                        loading={isLoading}
                        size={150}
                      />
                    </div>
                  )}
                  {error && (
                    <p style={{ color: 'red', marginBottom: '15px' }}>
                      {error}
                    </p>
                  )}
                  {isUserVerified && (
                    <div className={styles.verifiedBox}>
                      <div className={styles.verifiedBoxContent}>
                        <h3>Email Successfully Verified.</h3>
                        <button>
                          <Link to="/login">Login</Link>
                        </button>
                        {/* Your account is verified now, please{' '} */}
                        {/* <Link to="/login">
                          <span
                            style={{
                              color: green,
                              cursor: 'pointer',
                            }}
                            // onClick={() => {
                            //   // setFormType('login');
                            //   history.push('/login');
                            // }}
                            // onClick={() => history.push('/')}
                          >
                            {` login`}
                          </span>
                        </Link> */}
                      </div>
                    </div>
                  )}
                </>
              )}

              {showVerifyMessage && (
                <div className={styles.showVerify}>
                  <div className={styles.verifiedBoxContent}>
                    <img
                      alt="mail_icon"
                      src="https://thinksabio-asset.s3.amazonaws.com/Mailer-icon.png"
                    />
                    <h3>You are almost there.</h3>
                    <p>
                      Your account has been created successfully. Please check
                      your inbox and click on the verification link
                    </p>
                  </div>
                </div>
              )}
              <Tnc
                isVisible={openTnc}
                onCancel={() => {
                  setOpenTnc(false);
                }}
                onAgree={() => {
                  setOpenTnc(false);
                  setTncAccepted(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpForm;
