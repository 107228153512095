import React, { useState, useEffect, useRef } from 'react';

import {
  BarChartOutlined,
  NotificationOutlined,
  PieChartOutlined,
} from '@ant-design/icons';
// import { useLocation } from 'react-router-dom';

import Notification from './Notification';
import './Notification.css';

interface Props {
  authState?: string;
}

const NotificationContainer: React.FC<Props> = ({ authState }) => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const badgeVisible = authState === 'signedIn' ? true : false;
  const [badgePosition, setBadgePosition] = useState({
    top: 20,
    left: 440,
    // left: window.innerWidth - 70,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const badgeRef = useRef(null);

  // const location = useLocation(); // Get current location

  // const [shouldShowBadge, setShouldShowBadge] = useState(false);

  // Updated announcement text with JSX to render clickable links
  const announcementText = (
    <div>
      <h4 style={{ color: 'var(--whitecolor)' }}>
        New Graphical Representation Feature in TS-Diamonds Reports
      </h4>
      <p style={{ color: 'var(--whitecolor)', fontSize: '16px' }}>
        We're excited to introduce a new graphical representation feature in
        TS-Diamonds reports, providing an intuitive and interactive way to
        analyze stock performance and institutional trends. Here’s what you can
        expect:
      </p>
      <ul
        style={{
          color: 'var(--whitecolor)',
          fontSize: '16px',
          paddingLeft: '20px',
        }}
      >
        <li style={{ fontSize: '16px' }}>📊 Institutional Activity Trends</li>
        <li style={{ fontSize: '16px' }}>📈 Price and Volume Correlation</li>
        <li style={{ fontSize: '16px' }}>
          <PieChartOutlined style={{ fontSize: '18px', marginRight: '8px' }} />{' '}
          Sector Insights and Allocation
        </li>
        <li style={{ fontSize: '16px' }}>🎯 Interactive Data Points</li>
      </ul>
      <p style={{ color: 'var(--whitecolor)', fontSize: '16px' }}>
        To access the Diamonds Graphical Representation Report, simply click on
        The Below link will redirect you to a TS-Diamonds Report. and then
        simply click on the{' '}
        <BarChartOutlined
          style={{ fontSize: '18px', marginRight: '8px', color: '#8884d8' }}
        />{' '}
        icon beside the report title.
      </p>
      <p style={{ textAlign: 'center' }}>
        {/* Replace this <a> with <Link> if using React Router */}
        <a
          href="https://www.thinksabio.com/search/report/subtype=thinksabio_diamonds&type=thinksabio_diamonds"
          style={{
            color: '#0066cc',
            fontSize: '18px',
            fontWeight: 'bold',
            textDecoration: 'none',
          }}
        >
          Click Here to Navigate to the Report
        </a>
      </p>
    </div>
  );

  const toggleNotificationVisibility = () => {
    setIsNotificationVisible(!isNotificationVisible);
  };

  const startDrag = (e) => {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - badgeRef.current.offsetLeft,
      y: e.clientY - badgeRef.current.offsetTop,
    });
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  const handleDrag = (e) => {
    if (isDragging) {
      const newTop = e.clientY - dragOffset.y;
      const newLeft = e.clientX - dragOffset.x;

      setBadgePosition({
        top: newTop < 0 ? 0 : newTop,
        left: newLeft < 0 ? 0 : newLeft,
      });
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleDrag);
      window.addEventListener('mouseup', stopDrag);
    } else {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      window.removeEventListener('mousemove', handleDrag);
      window.removeEventListener('mouseup', stopDrag);
    };
  }, [isDragging]);

  return (
    <div>
      {/* Conditionally render the notification badge based on the URL */}
      {true && badgeVisible && (
        <div
          className="badge draggable hide-on-mobile"
          ref={badgeRef}
          style={{
            top: `${badgePosition.top}px`,
            left: `${badgePosition.left}px`,
          }}
          onMouseDown={startDrag}
          onClick={toggleNotificationVisibility}
        >
          {/* Cloud-like image or shape */}
          <div className="cloud-icon">
            <span className="cloud-text">New</span>
          </div>
          <NotificationOutlined className="notification-icon" />
        </div>
      )}

      {/* Notification Container */}
      {isNotificationVisible && (
        <div className="notification-container">
          <Notification
            message={announcementText}
            onClose={() => setIsNotificationVisible(false)}
          />
        </div>
      )}
    </div>
  );
};

export default NotificationContainer;
