import React from 'react';

import { MenuOutlined } from '@ant-design/icons';
import { Layout, Menu, Tooltip, Dropdown /*Tooltip*/ } from 'antd';
import { get } from 'lodash';
import { Link, NavLink } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';

import { green, isStudentUrl } from '../../utils';
import styles1 from '../../views/home/style2.module.scss';
import styles from './style.module.scss';

const { Header } = Layout;

interface Props extends RouteComponentProps<any> {
  onStateChange: Function;
  user: Object;
  menuItemStyle: Object;
  menuLinkStyle: Object;
  scrollY: number;
}
const isStudent = isStudentUrl();

let links = [
  // {
  //   name: 'Who we are',
  //   path: '/#why-ThinkSabio',
  //   text: '/#why-ThinkSabio',
  // },
  // {
  //   name: 'Why ThinkSabio',
  //   path: '/#why-ThinkSabio',
  //   text: '/#why-ThinkSabio',
  // },
  // {
  //   name: 'Success Stories',
  //   path: 'https://www.youtube.com/channel/UCxPVIwDYv-S-Tu_NVupgnWg',
  //   text: '/success-stories',
  // },
  {
    name: 'Join Us Now',
    path: '/sign-up',
    text: '/sign-up',
  },
  {
    name: 'Login',
    path: '/login',
    text: '/login',
  },
];

if (!isStudent) {
  links.push({
    name: 'Pricing',
    path: '/pricing',
    text: '/pricing',
  });
}

const StaticHeader: React.FC<Props> = ({
  history,
  match,
  onStateChange,
  menuItemStyle,
  menuLinkStyle,
  scrollY,
}) => {
  // let path = get(match, 'path', '');
  // const [visible, setVisible] = useState<boolean>(
  //   path === '/forgot-password/:username/:code' ||
  //     path === '/verify/:username/:code'
  //     ? true
  //     : false,
  // );
  // const pagePath = get(match, 'path');

  const menu = (
    <Menu className={styles1.mobileMenuNavLink}>
      {/*<Menu.Item key="0">
        <NavLink to="/stories">Stories</NavLink>
      </Menu.Item>*/}
      <Menu.Item key="0">
        <Tooltip
          placement="bottom"
          title={
            <>
              <div
                // className={styles.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Overview',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Overview</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles1.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Our Features',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Our Features</span>
                  </div>
                </NavLink>
              </div>
              <div
                className={styles1.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink
                  to={{
                    pathname: '/WhoWeAre',
                    state: {
                      name: {
                        properties: {
                          name: 'Success Stories',
                        },
                      },
                    },
                  }}
                >
                  <div style={{ padding: '10px' }}>
                    <span>Success Stories</span>
                  </div>
                </NavLink>
              </div>
            </>
          }
        >
          <span style={{ fontWeight: 'bold' }}>Who We Are</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to="/login">Login</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to="/sign-up">Sign Up</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <Tooltip
          placement="bottom"
          title={
            <>
              <div
                className={styles1.navLinks}
                style={{
                  padding: '0px 30px 0px 30px',
                  borderRadius: '5px',
                  backgroundColor: 'gainsboro',
                }}
              >
                <NavLink to="/contact-us">
                  <div style={{ padding: '10px' }}>
                    <span>Contact Us</span>
                  </div>
                </NavLink>
              </div>
              {/*<div*/}
              {/*  className={styles1.navLinks}*/}
              {/*  style={{*/}
              {/*    padding: '0px 30px 0px 30px',*/}
              {/*    marginTop: '10px',*/}
              {/*    borderRadius: '5px',*/}
              {/*    backgroundColor: 'gainsboro',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <NavLink to="/privacy-policy">*/}
              {/*    <div style={{ padding: '10px' }}>*/}
              {/*      <span>Privacy Policy</span>*/}
              {/*    </div>*/}
              {/*  </NavLink>*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  className={styles1.navLinks}*/}
              {/*  style={{*/}
              {/*    padding: '0px 30px 0px 30px',*/}
              {/*    marginTop: '10px',*/}
              {/*    borderRadius: '5px',*/}
              {/*    backgroundColor: 'gainsboro',*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <NavLink to="/tnc">*/}
              {/*    <div style={{ padding: '10px' }}>*/}
              {/*      <span>Terms & Conditions</span>*/}
              {/*    </div>*/}
              {/*  </NavLink>*/}
              {/*</div>*/}
            </>
          }
        >
          <span style={{ fontWeight: 'bold' }}>Support</span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  const selectedKeys = links
    .filter(({ path }) => RegExp(path).test(get(match, 'path')))
    .map(({ text }) => text);
  if (selectedKeys.length > 1) selectedKeys.shift();

  // const getFormState = () => {
  //   switch (path) {
  //     case '/forgot-password/:username/:code':
  //       return 'forgotPassword';
  //     case '/verify/:username/:code':
  //       return 'verifyAccount';
  //     case '/membership':
  //       return 'membership';
  //     default:
  //       return 'signup';
  //   }
  // };
  // onst [formType, setFormType] = useState<string>(getFormState());

  return (
    <div className={styles.headerSubHeader}>
      <Header
        className={styles.headerStaticMainBar}
        // style={{
        //   boxShadow:
        //     scrollY < 90
        //       ? '0px 0px 0px rgba(0, 0, 0, 0)'
        //       : '0px 4px 4px rgba(0, 0, 0, 0.05)',
        // }}
      >
        <div className={styles1.logoHeader}>
          <div style={{ marginRight: 'calc(15vh - 8px)' }}>
            <img
              src="https://thinksabio-asset.s3.amazonaws.com/TSLOGO.png"
              alt="logo"
            />
          </div>
          <div className={styles1.homeHeader}>
            <div className={styles1.headerContentBox}>
              <div className={styles1.headerlink}>
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  className={styles1.mobileMenuHome}
                  placement="bottomCenter"
                >
                  <p
                    onClick={(e) => e.preventDefault()}
                    className="ant-dropdown-link"
                  >
                    <MenuOutlined />
                  </p>
                </Dropdown>
                <ul className={styles1.menuLinkHome}>
                  <li>
                    <Tooltip
                      placement="bottom"
                      title={
                        <>
                          <div
                            className={styles1.navLinks}
                            style={{
                              padding: '0px 30px 0px 30px',
                              borderRadius: '5px',
                              backgroundColor: 'gainsboro',
                            }}
                          >
                            <NavLink
                              to={{
                                pathname: '/WhoWeAre',
                                state: {
                                  name: {
                                    properties: {
                                      name: 'Overview',
                                    },
                                  },
                                },
                              }}
                            >
                              <div style={{ padding: '10px' }}>
                                <span>Overview</span>
                              </div>
                            </NavLink>
                          </div>
                          <div
                            className={styles1.navLinks}
                            style={{
                              padding: '0px 30px 0px 30px',
                              marginTop: '10px',
                              borderRadius: '5px',
                              backgroundColor: 'gainsboro',
                            }}
                          >
                            <NavLink
                              to={{
                                pathname: '/WhoWeAre',
                                state: {
                                  name: {
                                    properties: {
                                      name: 'Our Features',
                                    },
                                  },
                                },
                              }}
                            >
                              <div style={{ padding: '10px' }}>
                                <span>Our Features</span>
                              </div>
                            </NavLink>
                          </div>
                          <div
                            className={styles1.navLinks}
                            style={{
                              padding: '0px 30px 0px 30px',
                              marginTop: '10px',
                              borderRadius: '5px',
                              backgroundColor: 'gainsboro',
                            }}
                          >
                            <NavLink
                              to={{
                                pathname: '/WhoWeAre',
                                state: {
                                  name: {
                                    properties: {
                                      name: 'Success Stories',
                                    },
                                  },
                                },
                              }}
                            >
                              <div style={{ padding: '10px' }}>
                                <span>Success Stories</span>
                              </div>
                            </NavLink>
                          </div>
                        </>
                      }
                    >
                      <button
                        style={{
                          width: 150,
                          color: 'white !important',
                          backgroundColor: 'black !important',
                        }}
                      >
                        Who We Are
                      </button>
                    </Tooltip>
                  </li>
                  {isStudent ? (
                    ''
                  ) : (
                    <>
                      {/*<li>*/}
                      {/*  <NavLink*/}
                      {/*    to={{*/}
                      {/*      pathname: '/donate',*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <button>Donate</button>*/}
                      {/*  </NavLink>*/}
                      {/*</li>*/}
                      <li>
                        <Link to="/free-trial">
                          <button style={{ color: green }}>Sign Up</button>
                        </Link>
                      </li>
                    </>
                  )}
                  {isStudent ? (
                    <li style={{ marginRight: '40px' }}>
                      <Link to="/sign-up">
                        <button
                          style={{
                            color: green,
                            width: '121%',
                            marginRight: '100px',
                            height: '50px',
                          }}
                        >
                          Join Us Now For Free
                        </button>
                      </Link>
                    </li>
                  ) : (
                    ''
                    // <li>
                    //   <Link to="/pricing">
                    //     <button>Pricing</button>
                    //   </Link>
                    // </li>
                  )}
                  <li>
                    <Link to="/login">
                      <button>Login</button>
                    </Link>
                  </li>
                  <li>
                    <Tooltip
                      placement="bottom"
                      title={
                        <>
                          <div
                            className={styles1.navLinks}
                            style={{
                              padding: '0px 30px 0px 30px',
                              borderRadius: '5px',
                              backgroundColor: 'gainsboro',
                            }}
                          >
                            <NavLink to="/contact-us">
                              <div style={{ padding: '10px' }}>
                                <span>Contact Us</span>
                              </div>
                            </NavLink>
                          </div>
                          {/*<div*/}
                          {/*  className={styles1.navLinks}*/}
                          {/*  style={{*/}
                          {/*    padding: '0px 30px 0px 30px',*/}
                          {/*    marginTop: '10px',*/}
                          {/*    borderRadius: '5px',*/}
                          {/*    backgroundColor: 'gainsboro',*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  <NavLink to="/privacy-policy">*/}
                          {/*    <div style={{ padding: '10px' }}>*/}
                          {/*      <span>Privacy Policy</span>*/}
                          {/*    </div>*/}
                          {/*  </NavLink>*/}
                          {/*</div>*/}
                          {/*<div*/}
                          {/*  className={styles1.navLinks}*/}
                          {/*  style={{*/}
                          {/*    padding: '0px 30px 0px 30px',*/}
                          {/*    marginTop: '10px',*/}
                          {/*    borderRadius: '5px',*/}
                          {/*    backgroundColor: 'gainsboro',*/}
                          {/*  }}*/}
                          {/*>*/}
                          {/*  <NavLink to="/tnc">*/}
                          {/*    <div style={{ padding: '10px' }}>*/}
                          {/*      <span>Terms & Conditions</span>*/}
                          {/*    </div>*/}
                          {/*  </NavLink>*/}
                          {/*</div>*/}
                        </>
                      }
                    >
                      <button
                        style={{
                          width: 150,
                          color: 'white !important',
                          backgroundColor: 'black !important',
                        }}
                      >
                        Support
                      </button>
                    </Tooltip>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*<Menu*/}
        {/*  theme="light"*/}
        {/*  mode="horizontal"*/}
        {/*  className={styles.headerMenuBar}*/}
        {/*  defaultSelectedKeys={['0']}*/}
        {/*></Menu>*/}
      </Header>
    </div>
  );
};

export default StaticHeader;
